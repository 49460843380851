import React, { useState } from "react";
import { Text } from "../Text";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import styled from "styled-components";

const ViewMessage = styled(Text)`
  cursor: pointer;
`;

export const ReadMore = ({ text, id, readStatus, refetch }) => {
  const axiosPrivate = useAxiosPrivate();
  const [read, setRead] = useState(readStatus);

  const toggleReadMore = async (id) => {
    if (id) {
      try {
        const response = await axiosPrivate.patch(
          `/notification/update/${id}`,
          {
            read: true,
          }
        );
        if (response.data) {
          setRead(!read);
        }
      } catch (err) {
        toast.error("Couldn't mark notification as read. An Error occured!");
      }
    }
  };

  return (
    <p className="text">
      {read === true ? text : text.slice(0, 0)}

      {read !== true && (
        <ViewMessage
          color="#222"
          weight="bold"
          type="p"
          onClick={() => toggleReadMore(id)}
        >
          View message
        </ViewMessage>
      )}
    </p>
  );
};
