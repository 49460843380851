import React from "react";
import { Text } from "../../atoms";
import styled from "styled-components";
import { brandTheme, screen } from "../../../theme";

const Wrapper = styled.div`
  background-color: ${brandTheme.brand.backgroundSkin};
  min-height: 90vh;
  // padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
  padding-top: 24px;

  @media only screen and (${screen.md}) {
    padding-bottom: 0;
  }
`;
const FormSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
`;
const FormSection = styled.section`
  background-color: ${brandTheme.brand.backgroundSkin4};
  border-radius: 16px;
  padding: 16px;
  height: fit-content;

  @media only screen and (${screen.md}) {
    padding: 16px 34px;
  }
`;
const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  @media only screen and (${screen.md}) {
    width: 50%;
  }
`;

export const PartnerOnboardTemplate = ({ children }) => {
  return (
    <Wrapper>
      <FormSectionWrapper>
        <FormSection className="flex flex-col items-center w-full md:w-2/3 gap-4 rounded">
          <TitleWrapper>
            <Text textalign="center" type="h2">
              Let’s get to know you more
            </Text>
            <p className="w-5/6 text-center">
              Our KYC process is a quick way for us to know how best to serve
              you!
            </p>
          </TitleWrapper>

          {children}
        </FormSection>
      </FormSectionWrapper>
    </Wrapper>
  );
};
