import styled, { css } from "styled-components";
import { africredColors } from "../../../theme/colors";
import { Icon } from "../../atoms/Icon";
import { Text } from "../../atoms/Text";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { motion } from "framer-motion";
import { pageAnimationConfig } from "../../../utils";
import { screen } from "../../../theme";
import { useState } from "react";
import { SelectInput } from "../../atoms/Input";
import { ApplicationCard } from "../../organisms";
import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { RequestDetailsModal } from "../../molecules/Modal/RequestDetailsModal";
import { Link } from "react-router-dom";
import { Loader } from "../../atoms";
import { Skeleton } from "antd";

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [200, 70, 180, 8, 45, 50],
      borderColor: africredColors.primary.green[500],
      backgroundcolor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};
const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  padding-bottom: 30px;

  @media only screen and (${screen.xl}) {
    padding-bottom: 0;
  }
`;
const highlightStyles = css`
  display: flex;
  height: 112px;
  padding: 36px 52px;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: ${africredColors.primary.green[600]};
  overflow-x: auto;
  overflow-y: hidden;
  grid-row: 1/2;
  grid-column: 1/6;
  color: white;

  > div {
    padding: 0px 24px;
    display: flex;
    gap: 28px;
    align-items: center;
  }

  p {
    text-wrap: nowrap;
  }
`;
const Highlight = styled.section`
  ${highlightStyles};
  display: none;

  @media only screen and (${screen.lg}) {
    height: 178px;
    border-radius: 32px;
    padding: 36px 35px;
    display: flex;
  }
`;
const MobileHighlightWrapper = styled.div`
  ${highlightStyles};

  > div {
    padding: 0px 16px;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 112px;
  border-radius: 16px;
  padding: 36px 1rem;

  @media only screen and (${screen.lg}) {
    display: none;
  }
`;
const sectionStyles = css`
  padding: 23px 12px;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};

  @media only screen and (${screen.md}) {
    padding: 20px;
  }
`;
const ActivityChart = styled.section`
  ${sectionStyles};
  display: flex;
  min-height: 280px;
  flex-direction: column;
  align-items: space-between;
  gap: 24px;
`;
const ChartWrapper = styled.div`
  height: auto;
  width: 100%;
`;
const ApplicationPool = styled.section`
  ${sectionStyles};
`;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const HightlightNavButton = styled.button`
  cursor: pointer;
  border-radius: 1rem;

  &:last-child {
    border: 1px solid #00e785;
    padding: 12px;
    background: #00e785;
  }
`;

const MobileHighlight = ({ highlightData }) => {
  const [currentDataIndex, setCurrentDataIndex] = useState(0);

  return (
    <MobileHighlightWrapper>
      <div>
        <div>{highlightData[currentDataIndex].icon}</div>
        <div>
          <Text type="h3">{highlightData[currentDataIndex].amount}</Text>
          <Text type="p" color="white">
            {highlightData[currentDataIndex].text}
          </Text>
        </div>
      </div>

      <div className="flex gap-2">
        <HightlightNavButton
          onClick={() => {
            setCurrentDataIndex((prev) => {
              if (highlightData[prev - 1]) {
                return prev - 1;
              }
              return prev;
            });
          }}
        >
          <Icon type="back" stroke="#fff" />
        </HightlightNavButton>
        <HightlightNavButton
          onClick={() => {
            setCurrentDataIndex((prev) => {
              if (highlightData[prev + 1]) {
                return prev + 1;
              }
              return prev;
            });
          }}
        >
          <Icon type="next" />
        </HightlightNavButton>
      </div>
    </MobileHighlightWrapper>
  );
};

export const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [statsFilter, setStatsFilter] = useState("Remittance");
  const [openModal, setOpenModal] = useState(false);
  const {
    isPending,
    error,
    data: dashboardData,
  } = useQuery({
    queryKey: ["dashboard-data"],
    queryFn: async () => {
      const response = await axiosPrivate("/transaction/partner-dashboard");
      return response.data;
    },
  });
  const { isPending: statLoading, data: stats } = useQuery({
    queryKey: ["fetch-stats", statsFilter],
    queryFn: async () => {
      const response = await axiosPrivate(
        `/transaction/partner-stats?transactionType=${
          statsFilter === "Short term loans" ? "Pof" : statsFilter
        }&range=Last 6 months`
      );
      return response.data;
    },
  });
  const [activePoll, setActivePoll] = useState("Loan");
  const chartStep =
    dashboardData?.graphPointsForEarnings?.earningsTotalAmounts?.reduce(
      (partialSum, a) => partialSum + a,
      0
    );

  if (isPending) return <Loader type="circle" />;

  if (error) return "An error has occurred: " + error.message;

  const highlightData = [
    {
      icon: <Icon stroke="white" type="transaction" />,
      amount: dashboardData.transactionStats.totalAcceptedLoans,
      text: "Accepted Loans",
    },
    {
      icon: <Icon stroke="white" type="card" />,
      amount: dashboardData.transactionStats.totalRejectedLoans,
      text: "Rejected Loans",
    },
    {
      icon: <Icon stroke="white" type="wallet" />,
      amount: dashboardData.transactionStats.totalAcceptedPofs,
      text: "Accepted POFs",
    },
    {
      icon: <Icon stroke="white" type="proof-of-funds" />,
      amount: dashboardData.transactionStats.totalRejectedPofs,
      text: "Rejected POFs",
    },
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: chartStep / 4,
        },
      },
    },
    tension: 0.4,
  };

  const data = {
    labels: stats?.graphPoints?.monthNames,
    datasets: [
      {
        label: "Dataset 1",
        data: stats?.graphPoints?.totalAmounts,
        borderColor: africredColors.primary.gold[500],
        backgroundcolor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <Wrapper {...pageAnimationConfig}>
      <Highlight>
        {highlightData.map((item) => (
          <div>
            <div>{item.icon}</div>
            <div>
              <Text type="h3">{item.amount}</Text>
              <Text type="p" color="#fff">
                {item.text}
              </Text>
            </div>
          </div>
        ))}
      </Highlight>

      <MobileHighlight highlightData={highlightData} />

      <ApplicationPool>
        <div className="flex w-full items-center justify-between">
          <div>
            <Text type="h4">Application pool</Text>
          </div>
          <div className="flex items-center gap-4">
            <SelectInput
              bgcolor="transparent"
              border={`1px solid ${africredColors.neutrals.night["300"]}`}
              options={["Loan", "Short term loans"]}
              value={activePoll}
              setValue={(val) => setActivePoll(val)}
              size="sm"
            />
            <Link
              to={activePoll === "Loan" ? "/loan-requests" : "/pof-requests"}
            >
              <Text>View all</Text>
            </Link>
          </div>
        </div>

        <div className="flex flex-wrap w-full mt-5 gap-10">
          {dashboardData.pools[
            activePoll === "Loan" ? "loanPool" : "pofPool"
          ].map((item) => (
            <ApplicationCard
              name={`${item.firstName} ${item.lastName}`}
              refId={item.referenceNumber}
              amount={item.pofAmount || item.loanAmount}
              purpose={item.purpose}
              key={item.id}
              currency={item.currency}
              daysInApplication={Math.round(item.daysOfApplication)}
              viewApplication={() => {
                setTransactionDetails(item);
                setOpenModal(true);
              }}
            />
          ))}
        </div>

        {transactionDetails && (
          <RequestDetailsModal
            title="Details"
            open={openModal}
            setOpen={setOpenModal}
            data={transactionDetails}
            requestType={activePoll}
          />
        )}
      </ApplicationPool>

      <ActivityChart>
        <div className="flex w-full items-center justify-between">
          <div>
            <Text type="h4">Activity</Text>
          </div>
          <div className="flex items-center gap-4">
            <Text type="p">Last 6 months</Text>
            <SelectInput
              width="fit-content"
              options={["Remittance", "Loan", "Short term loans"]}
              value={statsFilter}
              setValue={(item) => setStatsFilter(item)}
              size="sm"
              bgcolor="transparent"
              border="0"
            />
          </div>
        </div>
        <ChartWrapper>
          {statLoading ? (
            <Skeleton active />
          ) : (
            <Line options={options} data={data} />
          )}
        </ChartWrapper>
      </ActivityChart>
    </Wrapper>
  );
};
