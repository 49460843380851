import React from "react";
import styled from "styled-components";
import { Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";

const TimeWrapper = styled.div`
  padding: 0 24px 24px 24px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  &:not(:last-child) {
    border-left: 1px solid green;
  }

  > .time-icon {
    position: absolute;
    top: 0;
    left: -12px;
    background-color: white;
  }

  > .title {
    margin-top: -2px;
  }
`;

export const TimelineIndicator = ({ data }) => {
  return (
    <>
      {data.map((item, idx) => (
        <TimeWrapper key={idx}>
          <Text type="h5" className="title">
            {item.title}
          </Text>
          <Text
            type="p"
            color={africredColors.neutrals.night[300]}
            hovercolor={africredColors.neutrals.night[700]}
          >
            {item.content}
          </Text>
          <div className="time-icon">{item.icon}</div>
        </TimeWrapper>
      ))}
    </>
  );
};
