import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "../../";
import { Button, HorizontalLine, Icon, Text } from "../../../atoms";
import { screen } from "../../../../theme";
import profileImage from "../../../../assets/pngs/profile-avatar.png";
import { africredColors } from "../../../../theme/colors";
import logo from "../../../../assets/svgs/full_logo.svg";
import { Link } from "react-router-dom";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import { formatNumber } from "../../../../utils";

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 576px;
  padding: 14px;
  position: relative;
  overflow-y: auto;

  @media only screen and (${screen.md}) {
    padding: 14px 32px;
  }
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: 36px;
  top: 36px;
`;
const InfoWrapper = styled.div`
  padding: 16px 24px;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid #d1d1d1;
`;
const ProfileImageWrapper = styled.img`
  width: 76px;
  height: 76px;
  padding: 0.2rem;
`;

export const RequestDetailsModal = ({ open, setOpen, data, requestType }) => {
  const [success, setSuccess] = useState(false);
  const { account, admissionStatus, countryOfStudy, schoolOfferLetter } = data;
  const axiosPrivate = useAxiosPrivate();
  const auth = useAuth();
  console.log(data);

  async function indicateInterest(id) {
    if (requestType?.toLowerCase() === "loan") {
      const response = await axiosPrivate.patch(`/loan/partner-interest/${id}`);
      if (response.data) {
        toast.success("Interest Indicated Successfully!");
        setSuccess(true);
      } else {
        toast.error("An error has occured. Please try again!");
      }
    } else if (requestType === "pof") {
      const response = await axiosPrivate.patch(`/pof/pof-interest/${id}`);
      if (response.data) {
        toast.success("Interest Indicated Successfully!");
        setSuccess(true);
      } else {
        toast.error("An error has occured. Please try again!");
      }
    }
  }

  return (
    <Modal isOpen={open} positionX="end">
      <Wrapper>
        {!success ? (
          <div display="flex w-full">
            <Text
              className="w-5/6 mt-3"
              type="h3"
              textWrap="wrap"
              weight="medium"
            >
              Details
            </Text>

            <div className="flex flex-col gap-6 mt-6">
              <InfoWrapper>
                <div className="flex gap-4 items-center">
                  <ProfileImageWrapper src={profileImage} alt="profile" />
                  <div className="flex flex-col gap-2">
                    <Text type="h4">{`${account.firstName} ${account.lastName}`}</Text>
                    <div className="flex gap-2">
                      <Text type="p">{`${account.phoneNumber.code}${account.phoneNumber.number}`}</Text>
                      <Text type="p">{account.email}</Text>
                    </div>
                    <Text type="p">
                      {requestType} application of {data.currency}{" "}
                      {requestType.toLowerCase() === "loan"
                        ? formatNumber(data.loanAmount)
                        : formatNumber(data.pofAmount)}
                    </Text>
                  </div>
                </div>
              </InfoWrapper>

              <HorizontalLine />

              <div className="flex flex-col gap-4">
                <Text type="p" weight="bold">
                  Personal details
                </Text>
                <div className="grid grid-cols-2 gap-4">
                  <InfoWrapper>
                    <Text type="h5">Gender</Text>
                    <Text type="p">{account.gender}</Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Text type="h5">Marital status</Text>
                    <Text type="p">Single</Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Text type="h5">City</Text>
                    <Text type="p">{account.address.city}</Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Text type="h5">Country</Text>
                    <Text type="p">{account.address.country}</Text>
                  </InfoWrapper>
                </div>
              </div>

              <HorizontalLine />

              <div className="flex flex-col gap-4">
                <Text type="p" weight="bold">
                  Application details
                </Text>
                <div className="grid grid-cols-2 gap-4">
                  <InfoWrapper>
                    <Text type="h5">City of residence</Text>
                    <Text type="p">{account.address.city}</Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Text type="h5">Country of study</Text>
                    <Text type="p">{countryOfStudy}</Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Text type="h5">Amount</Text>
                    <Text type="p">
                      {requestType.toLowerCase() === "loan"
                        ? data.loanAmount
                        : data.pofAmount}
                    </Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <Text type="h5">Admission status</Text>
                    <Text type="p">{admissionStatus}</Text>
                  </InfoWrapper>
                  <InfoWrapper>
                    <div className="flex justify-between">
                      <div>
                        <Text type="h5">School offer letter</Text>
                        <Text type="p">
                          {schoolOfferLetter ? "Available" : "Unavailable"}
                        </Text>
                      </div>
                      <div>
                        <a
                          href={schoolOfferLetter.Location}
                          download={`${data.firstName}-school-offer-letter`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon type="download-doc" />
                        </a>
                      </div>
                    </div>
                  </InfoWrapper>

                  <InfoWrapper>
                    <div className="flex justify-between">
                      <div>
                        <Text type="h5">Proof of ID</Text>
                        <Text type="p">
                          {data.proofOfIdentity.typeOfProofOfIdentity}
                        </Text>
                      </div>
                      <div>
                        <a
                          href={data.proofOfIdentity.file.Location}
                          download={`${data.firstName}-proof-of-identity`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon type="download-doc" />
                        </a>
                      </div>
                    </div>
                  </InfoWrapper>
                </div>
              </div>

              <HorizontalLine />

              <div className="flex justify-center">
                <Button
                  onClick={() => {
                    if (data?.partner?.id === auth.id) {
                      toast.info(
                        "You already indicated interest on this request!"
                      );
                    } else {
                      indicateInterest(data.id);
                    }
                  }}
                  width="280px"
                  size="md"
                  bgcolor={africredColors.primary.blue[700]}
                  color="white"
                >
                  I'm interested
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex h-[95vh] justify-center items-center">
            <div className="flex flex-col gap-4 items-center">
              <img src={logo} alt="logo" />
              <Text
                className="w-5/6 mt-3"
                type="h3"
                textWrap="wrap"
                weight="medium"
                textalign="center"
              >
                Thank you for your Interest
              </Text>
              <Text
                className="w-5/6"
                type="p"
                textalign="center"
                textWrap="wrap"
              >
                Lorem ipsum dolor sit amet consectetur. Aliquam eget malesuada
                malesuada nullam congue non volutpat. Consequat nec sit tortor
                sit. Ultrices id fames.
              </Text>
              <div className="flex justify-center">
                <Link to="/">
                  <Button
                    width="300px"
                    size="md"
                    bgcolor={africredColors.primary.blue[700]}
                    color="white"
                  >
                    Back to dashboard
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </Wrapper>
      <CloseButton onClick={() => setOpen(false)}>
        <Icon type="close" />
      </CloseButton>
    </Modal>
  );
};
