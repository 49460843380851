import React from "react";
import styled, { css } from "styled-components";
import { africredColors } from "../../../theme/colors";
import logo from "../../../assets/svgs/full_logo.svg";
import profileImage from "../../../assets/pngs/dp.png";
import { Button, Icon, Text } from "../../atoms";
import { DropdownMenu } from "../DropdownMenu";
import { brandTheme, screen } from "../../../theme";
import { Link } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import useAuth from "../../../hooks/useAuth";
import { NotificationDropdown } from "../NotificationDropdown";
// import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
// import { toast } from "react-toastify";
// import { debounce } from "../../../utils";

const roundedContainerCSS = css`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const NotificationWrapper = styled.div`
  ${roundedContainerCSS};
  border: 1px solid ${africredColors.neutrals.night[300]};
  padding: 12px;
`;

const ProfileAvatarWrapper = styled.img`
  ${roundedContainerCSS};
`;

const dropdownItemStyles = css`
  display: flex;
  padding: 16px 0px;

  gap: 24px;
  background: ${africredColors.neutrals.white[10]};

  &:not(:last-child) {
    border-bottom: 1px solid ${africredColors.neutrals.night[300]};
  }
`;

const ProfileMenuItems = styled.div`
  align-items: center;
  ${dropdownItemStyles}
`;

const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 16px 10px;
  border-bottom: none;
  height: 70px;
  position: fixed;
  top: fixed;
  z-index: 9999;
  background-color: inherit;

  @media only screen and (${screen.md}) {
    border-bottom: 1px solid ${africredColors.neutrals.night[300]};
    padding: 16px 20px;
    height: 94px;
  }
`;
const Hamburger = styled(Icon)`
  border: 0px;
  background: transparent;
  width: 40px;
  height: 32px;

  @media only screen and (${screen.md}) {
    display: none;
  }
`;
const Image = styled.img`
  width: 90px;

  @media only screen and (${screen.md}) {
    width: 100%;
  }
`;
const LogoWrapper = styled.div`
  display: none;
  @media only screen and (${screen.md}) {
    display: block;
  }
`;
// const SearchContainer = styled.div`
//   position: relative;
// `;
// const SearchResult = styled.div`
//   min-width: 730px;
//   position: absolute;
//   top: 64px;
//   left: 50%;
//   transform: translate(-50%, 0);
//   padding: 24px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   border-radius: 16px;
//   background: #fff;
//   box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);

//   > div {
//     padding: 12px 0;
//     width: 100%;
//   }

//   > div:not(:last-child) {
//     border-bottom: 1px solid ${africredColors.neutrals.night[200]};
//   }
// `;

export const NavBar = ({ setSideNav }) => {
  const logout = useLogout();
  // const [searchTerm, setSearchTerm] = useState("");
  const auth = useAuth();
  // const axiosPrivate = useAxiosPrivate();
  // const [searchResult, setSearchResult] = useState(null);

  // async function search() {
  //   if (searchTerm.length > 0) {
  //     try {
  //       const response = await axiosPrivate.get(
  //         `/generic-apis/search-all?account[0]=${auth.id}&searchTerm=${searchTerm}`
  //       );
  //       if (response.data) {
  //         setSearchResult(response.data);
  //       }
  //     } catch (err) {
  //       toast.error("An error occured. Try global search later!");
  //     }
  //   }
  // }

  // function searchHasResult() {
  //   return (
  //     searchResult?.loanResults?.length > 0 ||
  //     searchResult?.pofResults?.length > 0 ||
  //     searchResult?.remittanceResults?.length > 0
  //   );
  // }

  // const performSearch = debounce(() => search());

  return (
    <StyledNav>
      <div className="flex items-center gap-4">
        <Hamburger
          type="hamburger"
          onClick={() => {
            setSideNav((prev) => !prev);
          }}
        />
        <LogoWrapper>
          <Link to="/">
            <Image src={logo} alt="logo" />
          </Link>
        </LogoWrapper>
      </div>
      {/* <SearchContainer className="hidden md:block w-1/2 md:w-1/3">
        <Input
          type="search"
          size="sm"
          icon={<Icon type="search" />}
          paddingleft="3rem"
          bgcolor="transparent"
          placeholder="search"
          border={`1px solid ${africredColors.neutrals.night[300]}`}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm((prev) => e.target.value);
            performSearch();
          }}
        />
        {searchTerm?.length > 0 && searchHasResult() && (
          <SearchResult>
            {searchResult?.loanResults?.length > 0 &&
              searchResult?.loanResults?.map((item, idx) => (
                <ResultItem
                  name={`${item.firstName} ${item.lastName}`}
                  amount={item.amountInDollars}
                  purpose={item.purpose}
                  id={item.id}
                  category="Loans"
                  link="loans"
                  setSearchResult={setSearchResult}
                  setSearchTerm={setSearchTerm}
                />
              ))}
            {searchResult?.pofResults?.length > 0 &&
              searchResult?.pofResults?.map((item, idx) => (
                <ResultItem
                  name={`${item.firstName} ${item.lastName}`}
                  amount={item.amountInDollars}
                  purpose={item.purpose}
                  id={item.id}
                  category="Short term loans"
                  link="pofs"
                  setSearchResult={setSearchResult}
                  setSearchTerm={setSearchTerm}
                />
              ))}
            {searchResult?.remittanceResults?.length > 0 &&
              searchResult?.remittanceResults?.map((item, idx) => (
                <ResultItem
                  name={`${item.firstName} ${item.lastName}`}
                  amount={item.amountInDollars}
                  purpose={item.purpose}
                  id={item.id}
                  category="Remittance"
                  link="transactions"
                  setSearchResult={setSearchResult}
                  setSearchTerm={setSearchTerm}
                />
              ))}
          </SearchResult>
        )}
      </SearchContainer> */}
      <div className="flex gap-4">
        <DropdownMenu
          text={
            <NotificationWrapper>
              <Icon type="notification" />
            </NotificationWrapper>
          }
          right="-60px"
          minWidth="349px"
        >
          <NotificationDropdown />
        </DropdownMenu>
        <DropdownMenu
          text={
            <ProfileAvatarWrapper
              src={auth?.profilePicture?.Location || profileImage}
              alt="profile-image"
            />
          }
          right="0px"
          minWidth="271px"
        >
          <div>
            {[1].map((item) => (
              <Link key={item} to="/settings/profile">
                <ProfileMenuItems>
                  <Icon type="settings" />
                  <Text type="p">Settings</Text>
                </ProfileMenuItems>
              </Link>
            ))}
          </div>
          <Button
            onClick={logout}
            width="100%"
            size="md"
            bgcolor={brandTheme.brand.primary}
          >
            <Icon type="logout" />
            <Text type="p" weight="medium">
              Logout
            </Text>
          </Button>
        </DropdownMenu>
      </div>
    </StyledNav>
  );
};

// const ResultItem = ({
//   img,
//   name,
//   amount,
//   purpose,
//   id,
//   category,
//   link,
//   setSearchResult,
//   setSearchTerm,
// }) => {
//   const navigate = useNavigate();

//   return (
//     <div className="flex gap-8 items-center">
//       <img
//         style={{ width: "35px", height: "35px", borderRadius: "50%" }}
//         src={img || profileImage}
//         alt={`profile`}
//       />
//       <Text type="p" textWrap="nowrap">
//         {name}
//       </Text>
//       <Text type="p">${amount}</Text>
//       <Text type="p" textWrap="nowrap">
//         {purpose}
//       </Text>
//       <Button
//         width="fit-content"
//         size="sm"
//         border={`1px solid ${africredColors.neutrals.night[200]}`}
//         onClick={() => {
//           setSearchTerm("");
//           setSearchResult(null);
//           navigate(`/${link}?id=${id}`);
//         }}
//       >
//         <Text type="p" weight="medium" textWrap="nowrap">
//           In {category}
//         </Text>
//       </Button>
//     </div>
//   );
// };
