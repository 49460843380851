import React from "react";
import { africredColors } from "../../../theme/colors";
import styled from "styled-components";
import { Icon } from "../../atoms";
import { screen } from "../../../theme";

const StepIndicatorCircle = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  z-index: 1;
  align-items: center;

  svg {
    z-index: 2;
  }

  &:not(:last-child)::after {
    content: "";
    width: 45px;
    height: 2px;
    top: 15px;
    background: ${({ linebackground }) => linebackground};
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    flex-grow: 2;
    min-width: 40px;
    height: 40px;
    top: -12px;
    left: -12px;
    background: red;
    border-radius: 50%;
    background: ${({ background }) => background};
  }

  @media only screen and (${screen.md}) {
    &:not(:last-child)::after {
      width: 100px;
    }
  }
`;

export const StepProgressIndicator = ({ numberOfSteps, currentStep }) => {
  return Array(numberOfSteps)
    .fill()
    .map((_, idx) => {
      return (
        <StepIndicatorCircle
          key={idx}
          linebackground={
            currentStep > idx + 1
              ? africredColors.primary.green[600]
              : africredColors.neutrals.night[300]
          }
          background={
            currentStep >= idx + 1
              ? africredColors.primary.green[50]
              : africredColors.neutrals.night[100]
          }
        >
          <Icon
            type="checked-circle"
            stroke={
              currentStep >= idx + 1
                ? africredColors.primary.green[600]
                : africredColors.neutrals.night[300]
            }
          />
        </StepIndicatorCircle>
      );
    });
};
