import { Icon } from "../../atoms/Icon";

export const sideNavLinks = {
  top: [
    {
      name: "Dashboard",
      link: "/",
      icon: <Icon type="3-cube" />,
    },
    {
      name: "Loans",
      link: "/loans",
      icon: <Icon type="wallet-sm" />,
    },
    {
      name: "Short term loans",
      link: "/proof-of-funds",
      icon: <Icon type="proof-of-funds-sm" />,
    },
    {
      name: "Notifications",
      link: "/notifications",
      icon: <Icon type="notification" />,
    },
    {
      name: "Support",
      link: "/support",
      icon: <Icon type="support" />,
    },
  ],
  bottom: [
    // {
    //   name: "Support",
    //   link: "/support",
    //   icon: <Icon type="support" />,
    // },
  ],
  settingLinks: [
    {
      name: "Profile",
      link: "/settings/profile",
      icon: <Icon type="person" />,
    },
    {
      name: "KYC Settings",
      link: "/settings/kyc",
      icon: <Icon type="security" />,
    },
    {
      name: "Login & Security",
      link: "/settings/login-security",
      icon: <Icon type="security" />,
    },
    {
      name: "Preference",
      link: "/settings/preference",
      icon: <Icon type="preference" />,
    },
  ],
};
