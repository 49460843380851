import React from "react";
import logo from "../../../assets/svgs/full_logo.svg";
import { Button, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { Link } from "react-router-dom";

export const PageNotFound = () => {
  return (
    <div className="flex h-[95vh] justify-center items-center">
      <div className="flex flex-col gap-4 items-center">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>

        <Text
          className="w-5/6 mt-4"
          type="h3"
          textWrap="wrap"
          weight="medium"
          textalign="center"
        >
          Oops! It seems you've ventured into uncharted territory.
        </Text>
        <Text className="w-5/6" type="p" textalign="center" textWrap="wrap">
          The page you're looking for appears to be missing or has been moved.
          Please check the URL for typos or navigate back to our homepage to
          explore more.
        </Text>
        <div className="flex justify-center mt-4">
          <Link to="/">
            <Button
              width="300px"
              size="md"
              border={`1px solid ${africredColors.neutrals.night[500]}`}
              color="black"
            >
              Go back home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
