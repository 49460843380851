import { africredColors } from "./colors";

const {
  neutrals: { night, white },
  primary: { gold, blue, orange, green },
  secondary: { red },
} = africredColors;

export const brandTheme = {
  text: {
    primary: night[950],
    secondary: night[600],
    tetiary: night[100],
    white: white[100],
  },
  brand: {
    primary: green[500],
    secondary: blue[600],
    tetiary: orange[500],
    backgroundSkin1: blue[50],
    backgroundSkin2: gold[100],
    backgroundSkin3: green[50],
    backgroundSkin4: white[10],
    backgroundSkin: night[50],
  },
  success: green[500],
  error: red[500],
};

export const screenSizes = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

export const screen = {
  sm: `min-width: ${screenSizes.sm}`,
  md: `min-width: ${screenSizes.md}`,
  lg: `min-width: ${screenSizes.lg}`,
  xl: `min-width: ${screenSizes.xl}`,
  xxl: `min-width: ${screenSizes.xxl}`,
};
