import React from "react";
import styled, { css } from "styled-components";
import { screen } from "../../../theme";
import { africredColors } from "../../../theme/colors";

function resolveFontWeight(weight) {
  if (weight === "medium") {
    return "creato display medium";
  }
  if (weight === "bold") {
    return "creato display bold";
  }
  return "creato display regular";
}

export const Text = ({ className, type, children, weight, ...rest }) => {
  if (type === "h1") {
    return (
      <StyledH1
        style={{ fontFamily: resolveFontWeight(weight) }}
        {...rest}
        className={className}
      >
        {children}
      </StyledH1>
    );
  } else if (type === "h2") {
    return (
      <StyledH2
        style={{ fontFamily: resolveFontWeight(weight) }}
        {...rest}
        className={className}
      >
        {children}
      </StyledH2>
    );
  } else if (type === "h3") {
    return (
      <StyledH3
        style={{ fontFamily: resolveFontWeight(weight) }}
        {...rest}
        className={className}
      >
        {children}
      </StyledH3>
    );
  } else if (type === "h4") {
    return (
      <StyledH4
        style={{ fontFamily: resolveFontWeight(weight) }}
        {...rest}
        className={className}
      >
        {children}
      </StyledH4>
    );
  } else if (type === "h5") {
    return (
      <StyledH5
        style={{ fontFamily: resolveFontWeight(weight) }}
        {...rest}
        className={className}
      >
        {children}
      </StyledH5>
    );
  } else if (type === "p") {
    return (
      <StyledP
        style={{ fontFamily: resolveFontWeight(weight) }}
        {...rest}
        className={className}
      >
        {children}
      </StyledP>
    );
  } else if (type === "small") {
    return (
      <StyledText
        style={{ fontFamily: resolveFontWeight(weight) }}
        {...rest}
        className={className}
      >
        {children}
      </StyledText>
    );
  }
  return <StyledText className={className}>{children}</StyledText>;
};

const styles = css`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-style: ${({ fontStyle }) => fontStyle};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  line-height: ${({ lineHeight }) => lineHeight};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  text-align: ${({ textalign }) => textalign || "inherit"};
  text-decoration: ${({ textDecoration }) => textDecoration};
  text-transform: ${({ textTransform }) => textTransform};
  cursor: ${({ cursor }) => cursor};
  white-space: ${({ whitespace }) => whitespace};
  border-right: ${({ borderRight }) => borderRight};
  border-left: ${({ borderLeft }) => borderLeft};
  text-wrap: ${({ textWrap }) => textWrap};

  &:hover {
    color: ${({ hovercolor }) => hovercolor || "inherit"};
  }
`;
const StyledText = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.0625rem;
  letter-spacing: 3%;
  ${styles}
`;
const StyledH1 = styled.h1`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: "-2.8%";
  ${styles}

  @media only screen and (${screen.md}) {
    font-size: 4.20875rem;
  }
`;
const StyledH2 = styled.h2`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -4%;
  ${styles}

  @media only screen and (${screen.md}) {
    font-size: 3.1575rem;
    line-height: 3.7875rem;
  }
`;
const StyledH3 = styled.h3`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -3%;
  ${styles}

  @media only screen and (${screen.md}) {
    font-size: 2.36875rem;
  }
`;
const StyledH4 = styled.h4`
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -3%;
  ${styles}

  @media only screen and (${screen.md}) {
    font-size: 1.77688rem;
    line-height: 2.13225rem;
  }
`;
const StyledH5 = styled.h5`
  font-size: 1.2;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -3%;
  ${styles}

  @media only screen and (${screen.md}) {
    font-size: 1.33313rem;
    line-height: 1.59975rem;
  }
`;
const StyledP = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.1875rem;
  letter-spacing: -3%;
  color: ${africredColors.neutrals.night[600]};
  ${styles};

  @media only screen and (${screen.md}) {
    font-size: 1rem;
  }
`;
