import React, { useEffect, useState } from "react";
import { Text } from "../../atoms/Text";
import styled from "styled-components";
import { africredColors } from "../../../theme/colors";
import { Button } from "../../atoms";
import { Table } from "../../molecules";
import profileImage from "../../../assets/pngs/dp.png";
import { Link } from "react-router-dom";
import { camelCaseToSeparateWords, pageAnimationConfig } from "../../../utils";
import { motion } from "framer-motion";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { screen } from "../../../theme";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { RequestDetailsModal } from "../../molecules/Modal/RequestDetailsModal";
import { ClickAbleLabel, getLabelBgColor, getLabelColor } from "../Loans";
import { TableFilter } from "../../organisms";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const ContentWrapper = styled.div`
  width: 100%;
  max-height: calc(84vh - 94px);
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (${screen.md}) {
    padding: 24px;
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  max-height: calc(75vh - 94px);
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

function renderHeader(params) {
  return (
    <Text type="p" weight="medium">
      {params.colDef.headerName}
    </Text>
  );
}

export const ProofOfFunds = () => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useAuth();
  const [proofOfFunds, setProofOfFunds] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    sort: "",
  });

  const columns = [
    {
      field: "referenceNumber",
      headerName: "Reference ID",
      minWidth: 160,
      flex: 1.5,
      renderHeader,
    },
    {
      field: "issuedBy",
      headerName: "Issued by",
      minWidth: 200,
      flex: 1.5,
      renderHeader,
      renderCell: ({ value, row }) => {
        return (
          <Flex>
            <img
              style={{ width: "35px", height: "35px", borderRadius: "50%" }}
              src={row.account?.profilePicture?.Location || profileImage}
              alt={`profile${value}`}
            />
            <Text type="p">
              {value?.substring(0, 25)} {value.length > 25 ? "..." : ""}
            </Text>
          </Flex>
        );
      },
    },
    {
      field: "amount",
      minWidth: 160,
      headerName: "Amount",
      renderHeader,
    },
    {
      field: "currency",
      headerName: "Currency",
      renderHeader,
    },
    {
      field: "purpose",
      headerName: "Purpose",
      minWidth: 200,
      flex: 2.5,
      renderHeader,
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 110,
      flex: 1.5,
      renderHeader,
    },
    {
      field: "status",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      minWidth: 200,
      renderHeader,
      renderCell: ({ value, row }) => {
        return (
          <ClickAbleLabel
            backgroundcolor={getLabelBgColor(value)}
            color={getLabelColor(value)}
          >
            {camelCaseToSeparateWords(value)}
          </ClickAbleLabel>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 80,
      renderHeader,

      getActions: (params) => [
        ...[
          <GridActionsCellItem
            label="View Details"
            showInMenu
            onClick={async () => {
              await setTransactionDetails((prev) => params.row);
              setOpenModal(true);
            }}
          />,
        ],
      ],
    },
  ];

  useEffect(() => {
    async function getProofOfFunds() {
      try {
        const response = await axiosPrivate.get(
          `/pof/search?partner[0]=${auth.id}`
        );
        if (response.data) {
          const proofOfFundsData = response.data.foundPofs.map((item) => ({
            ...item,
            referenceNumber: item?.referenceNumber || "...",
            issuedBy: `${item.firstName} ${item.lastName}`,
            amount: `${item.pofAmount}`,
            date: item.createdAt,
            status: item.status,
            currency: item.currency,
          }));

          setProofOfFunds(proofOfFundsData);
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }

    getProofOfFunds();
  }, [auth.id, axiosPrivate]);

  return (
    <Wrapper {...pageAnimationConfig}>
      <div className="flex justify-between">
        <div>
          <Text type="h3" weight="medium">
            Short term loans
          </Text>
          <Text type="p">My short term loans applications</Text>
        </div>

        <Link to="/pof-requests">
          <Button
            size="sm"
            border={`1px solid ${africredColors.neutrals.night[950]}`}
          >
            <Text type="p" weight="medium">
              View requests
            </Text>
          </Button>
        </Link>
      </div>

      <ContentWrapper>
        <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
          <Text type="h5" weight="medium">
            All short term loans
          </Text>
          <div className="flex gap-x-4 gap-y-2 flex-wrap lg:flex-nowrap">
            <TableFilter
              filterData={filterData}
              setFilterData={setFilterData}
            />
          </div>
        </div>
        <TableWrapper>
          <Table
            columns={columns}
            rows={proofOfFunds
              .sort(function (a, b) {
                if (filterData.sort.toLowerCase() === "asc")
                  return new Date(b.date) - new Date(a.date);
                return new Date(a.date) - new Date(b.date);
              })
              .filter(
                (item) =>
                  item.referenceNumber
                    .toLowerCase()
                    .includes(filterData.searchTerm.toLowerCase()) ||
                  item.issuedBy
                    .toLowerCase()
                    .includes(filterData.searchTerm.toLowerCase()) ||
                  item.purpose
                    .toLowerCase()
                    .includes(filterData.searchTerm.toLowerCase())
              )}
            pageSize="8"
          />
        </TableWrapper>
      </ContentWrapper>

      {transactionDetails && (
        <RequestDetailsModal
          title="Details"
          open={openModal}
          setOpen={setOpenModal}
          data={transactionDetails}
          requestType="pof"
        />
      )}
    </Wrapper>
  );
};
