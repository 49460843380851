import { styled } from "styled-components";
import { inputSizes } from "../../../theme/typeScale";

const StyledButton = styled.button`
  border-radius: ${({ borderRadius }) => borderRadius || "48px"};
  background-color: ${({ bgcolor }) => bgcolor};
  border: ${({ border }) => border || "none"};
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => `${padding} !important` || "none"};
`;

const { lg, md, sm, xs } = inputSizes;

export const Button = ({ size, children, ...rest }) => {
  if (size === "lg") {
    return (
      <StyledButton {...rest} style={lg}>
        {children}
      </StyledButton>
    );
  } else if (size === "md") {
    return (
      <StyledButton {...rest} style={md}>
        {children}
      </StyledButton>
    );
  } else if (size === "sm") {
    return (
      <StyledButton {...rest} style={sm}>
        {children}
      </StyledButton>
    );
  } else if (size === "xs") {
    return (
      <StyledButton {...rest} style={xs}>
        {children}
      </StyledButton>
    );
  }
  return <StyledButton {...rest}>{children}</StyledButton>;
};
