import React, { useRef, useState } from "react";
import { resolveSize } from "../../../lib/resolveInputSize";
import { css, styled } from "styled-components";
import { africredColors } from "../../../theme/colors";
import { Icon } from "../Icon";
import { Text } from "../Text";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Select } from "antd";

const styles = css`
  width: ${({ width }) => width || "100%"};
  background-color: ${({ bgcolor }) =>
    bgcolor || africredColors.neutrals.night["80"]};
  border-radius: ${({ borderRadius }) => borderRadius || "2rem"};
  gap: 1rem;
  border: ${({ border }) => border};
  padding: ${({ padding, size }) => {
    if (padding) return padding;
    return size === "lg" || size === "md"
      ? "16px 48px"
      : size === "sm"
      ? "12px 40px"
      : "8px 32px";
  }};
  height: ${({ height, size }) => {
    if (height) return height;
    return size === "lg"
      ? "64px"
      : size === "md"
      ? "56px"
      : size === "sm"
      ? "48px"
      : "40px";
  }};

  &:focus {
    outline: 0.5px solid ${africredColors.neutrals.night[800]};
  }
`;
const StyledInput = styled.input`
  ${styles};
`;
const TextArea = styled.textarea`
  ${styles};
  padding: 18px 16px;
  min-height: 132px;
`;
const FullWidthDiv = styled.div`
  width: 100%;
  position: relative;
`;
const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  ${({ iconposition }) =>
    `${iconposition || "left"}: ${iconposition === "right" ? "10px" : "26px"}`};
  transform: translate(-50%, -50%);
`;
const SelectWrapper = styled.div`
  position: relative;
`;
const InputElement = styled.div`
  ${styles};
  padding: ${({ icon }) => (icon ? "18px 16px 18px 48px" : "18px 16px")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DropdownContent = styled.div`
  position: absolute;
  top: 70px;

  right: 0;
  z-index: 9;
  border-radius: 16px;
  background-color: ${africredColors.neutrals.white["50"]};
  min-width: 200px;
  padding: 16px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  max-height: 100px;
  overflow-y: auto;

  > ul > li {
    width: 100%;
    padding: 8px 0;
    cursor: pointer;
  }
`;
const AntdSelectWrapper = styled(Select)`
  ${styles};
  padding: 0.6rem;
  .ant-select-selector {
    background-color: ${({ bgcolor }) =>
      bgcolor || africredColors.neutrals.night[80]} !important;
    outline: none !important;
    border: none !important;
  }
`;

export const SelectInput = ({
  options,
  bgcolor,
  value = "Select",
  setValue = () => {},
  size,
  icon,
  border,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);
  // useDetectOutsideClick(ref, () => setShowOptions(false));

  function handleSelectOptionState(e) {
    if (e.key === "Enter") {
      setShowOptions((prev) => !prev);
    }

    setShowOptions((prev) => !prev);
  }

  return (
    <SelectWrapper>
      {icon && (
        <InputIcon>
          <Icon type={icon} />
        </InputIcon>
      )}
      <InputElement
        border={border}
        icon={icon}
        bgcolor={bgcolor}
        tabIndex={0}
        onClick={handleSelectOptionState}
        onKeyUp={handleSelectOptionState}
        size={size || "md"}
      >
        <Text type="p">{value}</Text>
        <Icon type="caret" />
      </InputElement>

      {showOptions && (
        <DropdownContent ref={ref}>
          <ul>
            {options.map((item, idx) => (
              <li
                key={idx}
                tabIndex={0}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setValue(item);
                    setShowOptions(false);
                  }
                }}
                onClick={() => {
                  setValue(item);
                  setShowOptions(false);
                }}
                className="flex gap-4 items-center"
              >
                <Text type="p">{item}</Text>
              </li>
            ))}
          </ul>
        </DropdownContent>
      )}
    </SelectWrapper>
  );
};

export const PasswordInput = ({ size, ...rest }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FullWidthDiv>
      <InputIcon iconposition="right">
        <Icon
          className="cursor-pointer"
          type="secret"
          onClick={() => setShowPassword((prev) => !prev)}
        />
      </InputIcon>
      <StyledInput
        {...rest}
        type={showPassword ? "text" : "password"}
        style={resolveSize(size)}
        paddingleft="16px"
      />
    </FullWidthDiv>
  );
};

export const Input = ({
  inputRef,
  iconposition,
  icon,
  type,
  size,
  ...rest
}) => {
  if (type === "textArea") return <TextArea {...rest} type={type}></TextArea>;

  if (type === "phone")
    return (
      <PhoneInput
        inputStyle={{
          height:
            size === "lg"
              ? "64px"
              : size === "md"
              ? "56px"
              : size === "sm"
              ? "48px"
              : "40px",
          borderRadius: "2rem",
          width: "100%",
          backgroundColor: africredColors.neutrals.night["80"],
          paddingRight: "8px",
          paddingLeft: "60px",
        }}
        buttonStyle={{
          borderRadius: "2rem 0 0 2rem",
          width: "fit-content",
          backgroundColor: africredColors.neutrals.night["80"],
          paddingRight: "8px",
          paddingLeft: "8px",
        }}
        dropdownStyle={{
          position: "absolute",
        }}
        country={"ng"}
        {...rest}
      />
    );

  return (
    <FullWidthDiv>
      {icon && <InputIcon iconposition={iconposition}>{icon}</InputIcon>}
      <StyledInput ref={inputRef} {...rest} type={type} size={size} />
    </FullWidthDiv>
  );
};

export const AntdSelect = ({
  size = "md",
  options,
  setValue,
  showSearch,
  placeholder = "Select",
  defaultValue,
  value,
  bgColor,
  loading,
}) => {
  return (
    <AntdSelectWrapper
      loading={loading}
      defaultValue={defaultValue}
      size={size}
      showSearch={showSearch}
      value={value || null}
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input?.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      onChange={(value) => {
        setValue(value);
      }}
      options={options}
      bgColor={bgColor}
    />
  );
};
