import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  formatSelectOptionForAntd,
  pageAnimationConfig,
  separatePhoneNumberString,
} from "../../../utils";
import { AntdSelect, Button, Icon, Input, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { screen } from "../../../theme";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

const FormInputs = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const FormRow = styled.div`
  display: flex;
  gap: 16px;
`;

const Wrapper = styled(motion.div)``;
const WhiteArea = styled.div`
  display: block;
  padding: 48px 16px;
  gap: px;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  margin-top: 28px;
  height: fit-content;
  display: flex;
  justify-content: center;

  @media only screen and (${screen.xl}) {
    display: flex;
    padding: 48px 64px;
  }
`;

export const KYCSettings = () => {
  const axiosPrivate = useAxiosPrivate();
  const [settings, setSettings] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(null);
  const auth = useAuth();

  async function handleUpdate() {
    if (settings && phoneNumber) {
      try {
        const response = await axiosPrivate.patch("/partner/kyc", {
          ...settings,
          phoneNumber: separatePhoneNumberString(phoneNumber),
          companyPhoneNumber: separatePhoneNumberString(companyPhoneNumber),
        });
        if (response.data) {
          toast.success("Update successful");
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }
  }

  useEffect(() => {
    async function fetchSettings() {
      try {
        const response = await axiosPrivate.get("/partner/partner-kyc-details");
        if (response.data) {
          setSettings(response.data);
          setPhoneNumber(
            `${response.data?.phoneNumber?.code}${response.data?.phoneNumber?.number}`
          );
          setCompanyPhoneNumber(
            `${response.data?.companyPhoneNumber?.code}${response.data?.companyPhoneNumber?.number}`
          );
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }

    fetchSettings();
  }, [axiosPrivate]);

  return (
    <Wrapper {...pageAnimationConfig}>
      <div className="flex justify-between items-center">
        <Text type="h3" weight="medium">
          KYC details
        </Text>
        <Button
          onClick={handleUpdate}
          size="md"
          border={`1px solid ${africredColors.neutrals.night[400]}`}
        >
          Update changes
        </Button>
      </div>
      {settings && (
        <WhiteArea>
          <FormInputs>
            <Text type="h4">Personal details</Text>
            <FormRow>
              <div className="w-full">
                <Input
                  icon={<Icon type="person" />}
                  type="text"
                  size="md"
                  placeholder="First Name"
                  autoComplete="off"
                  value={settings.firstName}
                  onChange={(e) =>
                    setSettings((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="w-full">
                <Input
                  icon={<Icon type="person" />}
                  type="text"
                  size="md"
                  placeholder="Last Name"
                  autoComplete="off"
                  value={settings.lastName}
                  onChange={(e) =>
                    setSettings((prev) => ({
                      ...prev,
                      lastName: e.target.value,
                    }))
                  }
                />
              </div>
            </FormRow>

            <FormRow>
              <div className="w-full">
                {/* <SelectInput
                  options={auth.enumValues.genderEnum}
                  value={settings.gender || "Gender"}
                  setValue={(val) => {
                    setSettings((prev) => ({
                      ...prev,
                      gender: val,
                    }));
                  }}
                /> */}
                <AntdSelect
                  options={formatSelectOptionForAntd(
                    auth.enumValues.genderEnum
                  )}
                  placeholder="Gender"
                  value={settings.gender}
                  setValue={(val) => {
                    setSettings((prev) => ({
                      ...prev,
                      gender: val,
                    }));
                  }}
                  size="md"
                />
              </div>
              <div className="w-full">
                <Input
                  size="md"
                  type="phone"
                  value={phoneNumber}
                  onChange={(val) => setPhoneNumber(val)}
                />
              </div>
            </FormRow>

            <Text type="h4">Organization details</Text>
            <FormRow>
              <div className="w-full">
                <Input
                  icon={<Icon type="bank" />}
                  type="text"
                  size="md"
                  placeholder="Name"
                  autoComplete="off"
                  value={settings.companyName}
                  onChange={(e) =>
                    setSettings((prev) => ({
                      ...prev,
                      companyName: e.target.value,
                    }))
                  }
                />
              </div>
            </FormRow>

            <FormRow>
              <div className="w-full">
                <Input
                  icon={<Icon type="url" />}
                  type="url"
                  size="md"
                  placeholder="Website URL"
                  autoComplete="off"
                  value={settings.companyWebsite}
                  onChange={(e) =>
                    setSettings((prev) => ({
                      ...prev,
                      companyWebsite: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="w-full">
                <Input
                  size="md"
                  type="phone"
                  value={companyPhoneNumber}
                  onChange={(val) => setCompanyPhoneNumber(val)}
                />
              </div>
            </FormRow>

            <FormRow>
              <div className="w-full">
                <Input
                  type="text"
                  size="md"
                  padding="12px"
                  placeholder="City"
                  autoComplete="off"
                  value={settings.address.city}
                  onChange={(e) =>
                    setSettings((prev) => ({
                      ...prev,
                      address: { ...prev.address, city: e.target.value },
                    }))
                  }
                />
              </div>

              <div className="w-full">
                <Input
                  type="text"
                  size="md"
                  padding="12px"
                  placeholder="Postal / zip code"
                  autoComplete="off"
                  value={settings.address.zip}
                  onChange={(e) =>
                    setSettings((prev) => ({
                      ...prev,
                      address: { ...prev.address, zip: e.target.value },
                    }))
                  }
                />
              </div>
            </FormRow>

            <FormRow>
              <div className="w-full">
                <Input
                  type="text"
                  size="md"
                  placeholder="State"
                  padding="12px"
                  autoComplete="off"
                  value={settings.address.state}
                  onChange={(e) =>
                    setSettings((prev) => ({
                      ...prev,
                      address: { ...prev.address, state: e.target.value },
                    }))
                  }
                />
              </div>
              <div className="w-full">
                <AntdSelect
                  showSearch
                  options={formatSelectOptionForAntd(
                    auth?.enumValues?.countryEnum
                  )}
                  placeholder="Select country"
                  value={settings.address?.country}
                  defaultValue={settings.address?.country}
                  setValue={(val) =>
                    setSettings((prev) => ({
                      ...prev,
                      address: { ...prev.address, country: val },
                    }))
                  }
                  size="md"
                />
              </div>
            </FormRow>
          </FormInputs>
        </WhiteArea>
      )}
    </Wrapper>
  );
};
