import React, { useState } from "react";
import { Text } from "../../atoms/Text";
import styled from "styled-components";
import { africredColors } from "../../../theme/colors";
import { formatNumber, pageAnimationConfig } from "../../../utils";
import { motion } from "framer-motion";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { screen } from "../../../theme";
import { ApplicationCard, TableFilter } from "../../organisms";
import { useQuery } from "@tanstack/react-query";
import { RequestDetailsModal } from "../../molecules/Modal/RequestDetailsModal";
import { Loader } from "../../atoms";

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const ContentWrapper = styled.div`
  width: 100%;

  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media only screen and (${screen.md}) {
    padding: 24px;
  }

  @media only screen and (${screen.lg}) {
    max-height: calc(84vh - 94px);
  }
`;

export const ProofOfFundsRequests = () => {
  const axiosPrivate = useAxiosPrivate();
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filterData, setFilterData] = useState({
    searchTerm: "",
    sort: "",
  });
  const {
    isPending,
    error,
    data: pofRequests,
  } = useQuery({
    queryKey: ["pof-requests"],
    queryFn: async () => {
      const response = await axiosPrivate(
        "/pof/search?status[0]=Sent To Partner"
      );
      return response.data;
    },
  });

  if (isPending) return <Loader type="circle" />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <Wrapper {...pageAnimationConfig}>
      <div className="flex justify-between">
        <div>
          <Text type="h3" weight="medium">
            Short term loans pool
          </Text>
          <Text type="p" textWrap="nowrap">
            All short term loans applications
          </Text>
        </div>
      </div>

      <ContentWrapper>
        <div className="flex justify-between items-center gap-y-2 items-center flex-wrap lg:flex-nowrap">
          <Text type="h5" weight="medium">
            All short term loans
          </Text>
          <div className="flex gap-x-4 gap-y-2 flex-wrap lg:flex-nowrap">
            <TableFilter
              filterData={filterData}
              setFilterData={setFilterData}
            />
          </div>
        </div>

        <div className="flex flex-wrap w-full gap-x-8 gap-y-2">
          {pofRequests.foundPofs
            .sort(function (a, b) {
              if (filterData.sort.toLowerCase() === "asc")
                return new Date(b.date) - new Date(a.date);
              return new Date(a.date) - new Date(b.date);
            })
            .filter(
              (item) =>
                item.referenceNumber
                  ?.toLowerCase()
                  ?.includes(filterData.searchTerm.toLowerCase()) ||
                item.firstName
                  .toLowerCase()
                  .includes(filterData.searchTerm.toLowerCase()) ||
                item.purpose
                  .toLowerCase()
                  .includes(filterData.searchTerm.toLowerCase())
            )
            .map((item) => (
              <ApplicationCard
                name={`${item.firstName} ${item.lastName}`}
                refId={item.referenceNumber}
                amount={formatNumber(item.pofAmount)}
                purpose={item.purpose}
                key={item.id}
                currency={item.currency}
                daysInApplication={Math.round(item.daysOfApplication)}
                viewApplication={() => {
                  setTransactionDetails(item);
                  setOpenModal(true);
                }}
              />
            ))}
        </div>
        {transactionDetails && (
          <RequestDetailsModal
            title="Details"
            open={openModal}
            setOpen={setOpenModal}
            data={transactionDetails}
            requestType="pof"
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
