import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  AccessDenied,
  Dashboard,
  ForgotPassword,
  KYCSettings,
  LoanRequests,
  Loans,
  Login,
  LoginAndSecuritySettings,
  Notifications,
  PageNotFound,
  PreferenceSettings,
  ProfileSettings,
  ProofOfFunds,
  ProofOfFundsRequests,
  ResetPassword,
  Signup,
} from "./components/pages";
import { DashboardTemplate } from "./components/templates/DashboardTemplate";
import { AnimatePresence } from "framer-motion";
import { Support } from "./components/pages/Support";
import RequireAuth from "./components/utils/RequireAuth";
import { UpdateProfileContact } from "./components/pages/UpdateProfileContact";
import { OnboardPartner } from "./components/pages/OnboardPartner";
import { ErrorBoundary } from "./components/organisms";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <DashboardTemplate />
      </RequireAuth>
    ),
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/loans",
        element: <Loans />,
      },
      {
        path: "/loan-requests",
        element: <LoanRequests />,
      },
      {
        path: "/pof-requests",
        element: <ProofOfFundsRequests />,
      },
      {
        path: "/proof-of-funds",
        element: <ProofOfFunds />,
      },
      {
        path: "/settings/profile",
        element: <ProfileSettings />,
      },
      {
        path: "/settings/kyc",
        element: <KYCSettings />,
      },
      {
        path: "/settings/login-security",
        element: <LoginAndSecuritySettings />,
      },
      {
        path: "/settings/preference",
        element: <PreferenceSettings />,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/onboard-partner",
    errorElement: <ErrorBoundary />,
    element: (
      <RequireAuth>
        <OnboardPartner />
      </RequireAuth>
    ),
  },
  {
    path: "/update-contact/:type",
    errorElement: <ErrorBoundary />,
    element: (
      <RequireAuth>
        <UpdateProfileContact />
      </RequireAuth>
    ),
  },
  {
    path: "/login",
    errorElement: <ErrorBoundary />,
    element: <Login />,
  },
  {
    path: "/signup",
    errorElement: <ErrorBoundary />,
    element: <Signup />,
  },
  {
    path: "/forgot-password",
    errorElement: <ErrorBoundary />,
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    errorElement: <ErrorBoundary />,
    element: <ResetPassword />,
  },
  {
    path: "/notifications",
    errorElement: <ErrorBoundary />,
    element: (
      <RequireAuth>
        <Notifications />
      </RequireAuth>
    ),
  },
  {
    path: "/support",
    errorElement: <ErrorBoundary />,
    element: (
      <RequireAuth>
        <Support />
      </RequireAuth>
    ),
  },
  {
    path: "/unauthorized",
    errorElement: <ErrorBoundary />,
    element: <AccessDenied />,
  },
  {
    path: "*",
    errorElement: <ErrorBoundary />,
    element: <PageNotFound />,
  },
]);

const App = () => {
  return (
    <AnimatePresence mode="wait">
      <RouterProvider router={router} />
    </AnimatePresence>
  );
};

export default App;
