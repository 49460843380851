import React from "react";
import styled from "styled-components";
import { africredColors } from "../../../theme/colors";
import profileImage from "../../../assets/pngs/profile-avatar.png";
import { Text } from "../../atoms/Text";
import { Icon } from "../../atoms/Icon";
import { HorizontalLine } from "../../atoms/HorizontalLine";
import { Button } from "../../atoms/Button";
import { screen } from "../../../theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background: ${africredColors.neutrals.white[10]};
  padding: 24px 0;
  width: 100%;

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  @media only screen and (${screen.md}) {
    width: 46%;
  }

  @media only screen and (${screen.lg}) {
    width: 30%;
  }
`;

export const ApplicationCard = ({
  name,
  refId,
  amount,
  purpose,
  id,
  daysInApplication,
  viewApplication,
  currency,
}) => {
  return (
    <Wrapper>
      <div className="flex w-full justify-between">
        <div className="flex gap-3">
          <img src={profileImage} alt="user-img" />
          <div className="flex flex-col gap-1">
            <Text type="h5">{name}</Text>
            {refId && <Text type="p">{`Ref ID: ${refId}`}</Text>}
          </div>
        </div>
        <Icon type="horizontal-ellipsis" />
      </div>
      <div className="w-full flex flex-col gap-2">
        <div className="flex gap-1">
          <Text type="h5" weight="bold">
            {currency}
          </Text>
          <Text type="h5">{amount}</Text>
        </div>
        <Text type="p">{purpose}</Text>
      </div>
      <HorizontalLine />
      <Button
        border={`1px solid ${africredColors.neutrals.night[300]}`}
        width="100%"
        size="md"
        bgcolor="transparent"
        onClick={viewApplication}
      >
        View application
      </Button>
      <div className="w-full flex justify-between">
        <Text type="p">Days in application</Text>
        <Text type="p">{daysInApplication}</Text>
      </div>
    </Wrapper>
  );
};
