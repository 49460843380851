import { useContext } from "react";
import AuthContext from "../store/contexts/AuthProvider";
import useAxiosPrivate from "./useAxiosPrivate";
import { toast } from "react-toastify";

const useLogout = () => {
  const { setAuth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();

  const logout = async () => {
    //TODO: Uncomment the code below when the have a /logout endpoint
    try {
      const response = await axiosPrivate.get("/partner/logout");

      if (response.status === 200) {
        setAuth({});
      }
    } catch (err) {
      toast.error("An error has occured.");
    }
  };

  return logout;
};

export default useLogout;
