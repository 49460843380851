import React from "react";

export const Icon = (props) => {
  if (props.type === "drag") {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={30} {...props}>
        <g
          data-name="drag (2)"
          transform="translate(-5.702 -1.503)"
          fill="#707070"
        >
          <circle
            data-name="Ellipse 836"
            cx={3}
            cy={3}
            r={3}
            transform="translate(5.702 1.503)"
          />
          <circle
            data-name="Ellipse 837"
            cx={3}
            cy={3}
            r={3}
            transform="translate(5.702 13.503)"
          />
          <circle
            data-name="Ellipse 838"
            cx={3}
            cy={3}
            r={3}
            transform="translate(5.702 25.503)"
          />
          <circle
            data-name="Ellipse 839"
            cx={3}
            cy={3}
            r={3}
            transform="translate(17.702 1.503)"
          />
          <circle
            data-name="Ellipse 840"
            cx={3}
            cy={3}
            r={3}
            transform="translate(17.702 13.503)"
          />
          <circle
            data-name="Ellipse 841"
            cx={3}
            cy={3}
            r={3}
            transform="translate(17.702 25.503)"
          />
        </g>
      </svg>
    );
  }
  if (props.type === "apple") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          fill="#151515"
          fillRule="evenodd"
          d="M15.4 2c.2 1.1-.3 2.2-.9 3-.6.8-1.7 1.5-2.8 1.4-.2-1.1.3-2.1.9-2.9.7-.8 1.8-1.4 2.8-1.5Zm3.2 17.4c.5-.8.8-1.3 1.2-2.2-3.1-1.2-3.6-5.6-.5-7.2-.9-1.2-2.2-1.8-3.5-1.8-.9 0-1.5.2-2.1.4-.5.2-.9.3-1.4.3-.6 0-1-.2-1.6-.4-.6-.2-1.2-.4-1.9-.4-1.4 0-2.9.8-3.8 2.3-1.3 2-1.1 5.9 1 9.1.9 1.2 1.9 2.5 3.2 2.5.6 0 .9-.2 1.3-.3.5-.2 1-.4 1.8-.4.9 0 1.3.2 1.8.4.4.2.7.3 1.3.3 1.4 0 2.4-1.5 3.2-2.6Z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
  if (props.type === "google") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          fill="#151515"
          d="M21.6 10.2h-9.4v3.7h5.5c-.1.9-.7 2.3-2 3.2-.8.6-2 1-3.5 1-2.6 0-4.9-1.7-5.7-4.2-.2-.6-.3-1.3-.3-2s.1-1.4.3-2c.1-.2.1-.4.2-.5.9-2.1 3-3.6 5.5-3.6 1.9 0 3.1.8 3.9 1.5l2.8-2.8C17.2 3 14.9 2 12.2 2 8.3 2 4.9 4.2 3.3 7.5c-.7 1.4-1.1 2.9-1.1 4.5s.4 3.1 1.1 4.5c1.6 3.3 5 5.5 8.9 5.5 2.7 0 5-.9 6.6-2.4 1.9-1.7 3-4.3 3-7.4 0-.8-.1-1.4-.2-2Z"
        />
      </svg>
    );
  }
  if (props.type === "secret") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="m14.53 9.47-5.06 5.06a3.576 3.576 0 1 1 5.06-5.06Z" />
          <path d="M17.82 5.77C16.07 4.45 14.07 3.73 12 3.73c-3.53 0-6.82 2.08-9.11 5.68-.9 1.41-.9 3.78 0 5.19.79 1.24 1.71 2.31 2.71 3.17M8.42 19.53c1.14.48 2.35.74 3.58.74 3.53 0 6.82-2.08 9.11-5.68.9-1.41.9-3.78 0-5.19-.33-.52-.69-1.01-1.06-1.47" />
          <path d="M15.51 12.7a3.565 3.565 0 0 1-2.82 2.82M9.47 14.53 2 22M22 2l-7.47 7.47" />
        </g>
      </svg>
    );
  }
  if (props.type === "email") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
        >
          <path d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5Z" />
          <path d="m17 9-3.13 2.5c-1.03.82-2.72.82-3.75 0L7 9" />
        </g>
      </svg>
    );
  }

  if (props.type === "logo") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || 27}
        height={props.width || 30}
        fill={props.fill || "none"}
        {...props}
      >
        <g fill="#151515">
          <path d="M14.3 3.263H10.88L7.05 13.748c-.713.115-1.164-.191-2.254.23-.563.23-2.892-1.76-3.193-2.296C.25 9.118 1.49 8.429 1.49 6.937c0-1.454.187-1.493.6-2.143 1.278-1.76 2.48-.766 2.48-2.488 0-.688.902-1.224 1.615-1.415.451-.154.977.076 1.39-.039C8.101.7 8.515.355 8.702.24c.676-.344 1.54-.306 2.142.038.713.383.75.727.901 1.454.15.46.864.919 1.277 1.034.414.076.827.497 1.278.497ZM26.96 10.916c-.189 1.646-.451 1.952-1.466 3.177-2.066 2.602-2.742 1.645-2.892 4.285L17.117 3.263H14.3c.15 0 .262-.038.413-.153.338-.23-.263-.689.037-1.033.226-.268 1.503.153 1.803.268.977.382 3.006-.192 3.156.038.639.88-.15 1.798.338 2.679.564 1.033 1.052 1.033 1.353 1.607.263.574.3 2.066.976 2.487 1.127.727.676.23 1.578 1.301.977 1.186 3.193-1.033 3.005.46ZM20.46 26.568c-.3.574-.75.918-.901.995-1.165.727-2.93 2.831-4.32 2.372-1.465-.46-.677-.88-1.315-1.837-.864-1.339-.075-1.339-1.202-3.214-.075-.115-.15-.23-.188-.383h7.213l.713 2.067ZM12.496 20.254V18.76l-.864-.689.038-.918c-.263-.344-.526-.497-.751-.689l3.042-8.533 4.47 12.628h-6.01l.075-.306Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "transaction") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        fill="none"
        {...props}
      >
        <g
          stroke={props.stroke || "#151515"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path
            strokeMiterlimit={10}
            d="M44 12v4.84C44 20 42 22 38.84 22H32V8.02C32 5.8 33.82 4 36.04 4c2.18.02 4.18.9 5.62 2.34A8.05 8.05 0 0 1 44 12Z"
          />
          <path
            strokeMiterlimit={10}
            d="M4 14v28c0 1.66 1.88 2.6 3.2 1.6l3.42-2.56c.8-.6 1.92-.52 2.64.2l3.32 3.34c.78.78 2.06.78 2.84 0l3.36-3.36c.7-.7 1.82-.78 2.6-.18l3.42 2.56c1.32.98 3.2.04 3.2-1.6V8c0-2.2 1.8-4 4-4H12c-6 0-8 3.58-8 8v2Z"
          />
          <path d="M12.5 20h11" />
        </g>
      </svg>
    );
  }

  if (props.type === "card") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={49}
        height={48}
        fill="none"
        {...props}
      >
        <g
          stroke={props.stroke || "#151515"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path
            strokeMiterlimit={10}
            d="M4.5 17.01h40M12.5 33.01h4M21.5 33.01h8"
          />
          <path d="M13.38 7.01H35.6c7.12 0 8.9 1.76 8.9 8.78v16.42c0 7.02-1.78 8.78-8.88 8.78H13.38c-7.1.02-8.88-1.74-8.88-8.76V15.79c0-7.02 1.78-8.78 8.88-8.78Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "wallet") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        fill="none"
        {...props}
      >
        <g
          stroke={props.stroke || "#151515"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path d="m25.32 5.036-.06.14-5.8 13.46h-5.7c-1.36 0-2.66.28-3.84.78l3.5-8.36.08-.2.14-.32c.04-.12.08-.24.14-.34 2.62-6.06 5.58-7.44 11.54-5.16Z" />
          <path d="M36.1 19.036c-.9-.28-1.86-.4-2.82-.4H19.46l5.8-13.46.06-.14c.3.1.58.24.88.36l4.42 1.86c2.46 1.02 4.18 2.08 5.22 3.36.2.24.36.46.5.72.18.28.32.56.4.86.08.18.14.36.18.52.54 1.68.22 3.74-.82 6.32Z" />
          <path d="M43.043 28.397v3.9c0 .4-.02.8-.04 1.2-.38 6.98-4.28 10.5-11.68 10.5h-15.6c-.48 0-.96-.04-1.42-.1-6.36-.42-9.76-3.82-10.18-10.18-.06-.46-.1-.94-.1-1.42v-3.9c0-4.02 2.44-7.48 5.92-8.98 1.2-.5 2.48-.78 3.84-.78h19.52c.98 0 1.94.14 2.82.4 3.98 1.22 6.92 4.94 6.92 9.36Z" />
          <path d="m13.42 11.056-3.5 8.36c-3.48 1.5-5.92 4.96-5.92 8.98v-5.86c0-5.68 4.04-10.42 9.42-11.48ZM43.037 22.535v5.86c0-4.4-2.92-8.14-6.92-9.34 1.04-2.6 1.34-4.64.84-6.34-.04-.18-.1-.36-.18-.52 3.72 1.92 6.26 5.86 6.26 10.34Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "proof-of-funds") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={49}
        height={48}
        fill="none"
        {...props}
      >
        <g
          stroke={props.stroke || "#151515"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path d="M22.003 33.72v4.06c0 3.44-3.2 6.22-7.14 6.22-3.94 0-7.16-2.78-7.16-6.22v-4.06c0 3.44 3.2 5.88 7.16 5.88 3.94 0 7.14-2.46 7.14-5.88Z" />
          <path d="M22 28.22c0 1-.28 1.92-.76 2.72-1.18 1.94-3.6 3.16-6.4 3.16-2.8 0-5.22-1.24-6.4-3.16-.48-.8-.76-1.72-.76-2.72 0-1.72.8-3.26 2.08-4.38 1.3-1.14 3.08-1.82 5.06-1.82 1.98 0 3.76.7 5.06 1.82C21.2 24.94 22 26.5 22 28.22Z" />
          <path d="M22.003 28.22v5.5c0 3.44-3.2 5.88-7.14 5.88-3.94 0-7.16-2.46-7.16-5.88v-5.5c0-3.44 3.2-6.22 7.16-6.22 1.98 0 3.76.7 5.06 1.82 1.28 1.12 2.08 2.68 2.08 4.4ZM44.5 21.94v4.12c0 1.1-.88 2-2 2.04h-3.92c-2.16 0-4.14-1.58-4.32-3.74-.12-1.26.36-2.44 1.2-3.26.74-.76 1.76-1.2 2.88-1.2h4.16c1.12.04 2 .94 2 2.04Z" />
          <path d="M4.5 21v-4c0-5.44 3.28-9.24 8.38-9.88.52-.08 1.06-.12 1.62-.12h18c.52 0 1.02.02 1.5.1 5.16.6 8.5 4.42 8.5 9.9v2.9h-4.16c-1.12 0-2.14.44-2.88 1.2-.84.82-1.32 2-1.2 3.26.18 2.16 2.16 3.74 4.32 3.74h3.92V31c0 6-4 10-10 10h-5" />
        </g>
      </svg>
    );
  }
  if (props.type === "3-cube") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#151515"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="m12.92 2.26 6.51 3.51c.76.41.76 1.58 0 1.99l-6.51 3.51c-.58.31-1.26.31-1.84 0L4.57 7.76c-.76-.41-.76-1.58 0-1.99l6.51-3.51c.58-.31 1.26-.31 1.84 0ZM3.61 10.13l6.05 3.03c.75.38 1.23 1.15 1.23 1.99v5.72c0 .83-.87 1.36-1.61.99l-6.05-3.03A2.238 2.238 0 0 1 2 16.84v-5.72c0-.83.87-1.36 1.61-.99ZM20.39 10.13l-6.05 3.03c-.75.38-1.23 1.15-1.23 1.99v5.72c0 .83.87 1.36 1.61.99l6.05-3.03c.75-.38 1.23-1.15 1.23-1.99v-5.72c0-.83-.87-1.36-1.61-.99Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "mini-transaction") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M6.728 19.7c.82-.88 2.07-.81 2.79.15l1.01 1.35c.81 1.07 2.12 1.07 2.93 0l1.01-1.35c.72-.96 1.97-1.03 2.79-.15 1.78 1.9 3.23 1.27 3.23-1.39V7.04c0-4.03-.94-5.04-4.72-5.04h-7.56c-3.78 0-4.72 1.01-4.72 5.04V18.3c.01 2.67 1.47 3.29 3.24 1.4ZM9.25 10h5.5" />
        </g>
      </svg>
    );
  }

  if (props.type === "support") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
          <path
            stroke="#5D5D5D"
            d="M11.97 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
          />
          <path
            stroke="#5D5D5D"
            d="M12 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM4.9 4.93l3.54 3.53M4.9 19.07l3.54-3.53M19.05 19.07l-3.54-3.53"
          />
          <path stroke="#212121" d="m19.05 4.93-3.54 3.53" />
        </g>
      </svg>
    );
  }

  if (props.type === "notification") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g stroke="#5D5D5D" strokeMiterlimit={10} strokeWidth={1.286}>
          <path
            strokeLinecap="round"
            d="M12.02 2.91c-3.31 0-6 2.69-6 6v2.89c0 .61-.26 1.54-.57 2.06L4.3 15.77c-.71 1.18-.22 2.49 1.08 2.93 4.31 1.44 8.96 1.44 13.27 0 1.21-.4 1.74-1.83 1.08-2.93l-1.15-1.91c-.3-.52-.56-1.45-.56-2.06V8.91c0-3.3-2.7-6-6-6Z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.87 3.2a6.754 6.754 0 0 0-3.7 0c.29-.74 1.01-1.26 1.85-1.26.84 0 1.56.52 1.85 1.26Z"
          />
          <path d="M15.02 19.06c0 1.65-1.35 3-3 3-.82 0-1.58-.34-2.12-.88a3.01 3.01 0 0 1-.88-2.12" />
        </g>
      </svg>
    );
  }

  if (props.type === "search") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M12 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22.5 22l-2-2" />
        </g>
      </svg>
    );
  }

  if (props.type === "caret") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          stroke="#151515"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
          d="m19.92 8.95-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
        />
      </svg>
    );
  }

  if (props.type === "up-caret-light") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        {...props}
      >
        <g id="vuesax/linear/arrow-down">
          <g id="arrow-down">
            <path
              id="Vector"
              d="M3.05994 11.2871L7.94994 6.39711C8.52744 5.81961 9.47244 5.81961 10.0499 6.39711L14.9399 11.2871"
              stroke="#5D5D5D"
              strokeWidth="1.125"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }

  if (props.type === "down-caret-light") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        {...props}
      >
        <g id="vuesax/linear/arrow-down">
          <g id="arrow-down">
            <path
              id="Vector"
              d="M14.9401 6.71289L10.0501 11.6029C9.47256 12.1804 8.52756 12.1804 7.95006 11.6029L3.06006 6.71289"
              stroke="#5D5D5D"
              strokeWidth="1.125"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }

  if (props.type === "arrow-45deg") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={34}
        height={34}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
        >
          <path d="M22.981 19.574V10.99h-8.584M10.96 23.01l11.9-11.9" />
        </g>
      </svg>
    );
  }

  if (props.type === "card-sm") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path
            strokeMiterlimit={10}
            d="M2 8.505h20M6 16.505h2M10.5 16.505h4"
          />
          <path d="M6.44 3.505h11.11c3.56 0 4.45.88 4.45 4.39v8.21c0 3.51-.89 4.39-4.44 4.39H6.44c-3.55.01-4.44-.87-4.44-4.38v-8.22c0-3.51.89-4.39 4.44-4.39Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "wallet-sm") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="m12.66 2.518-.03.07-2.9 6.73H6.88c-.68 0-1.33.14-1.92.39l1.75-4.18.04-.1.07-.16c.02-.06.04-.12.07-.17 1.31-3.03 2.79-3.72 5.77-2.58Z" />
          <path d="M18.05 9.518c-.45-.14-.93-.2-1.41-.2H9.73l2.9-6.73.03-.07c.15.05.29.12.44.18l2.21.93c1.23.51 2.09 1.04 2.61 1.68.1.12.18.23.25.36.09.14.16.28.2.43.04.09.07.18.09.26.27.84.11 1.87-.41 3.16Z" />
          <path d="M21.522 14.198v1.95c0 .2-.01.4-.02.6-.19 3.49-2.14 5.25-5.84 5.25h-7.8c-.24 0-.48-.02-.71-.05-3.18-.21-4.88-1.91-5.09-5.09-.03-.23-.05-.47-.05-.71v-1.95c0-2.01 1.22-3.74 2.96-4.49.6-.25 1.24-.39 1.92-.39h9.76c.49 0 .97.07 1.41.2 1.99.61 3.46 2.47 3.46 4.68Z" />
          <path d="m6.71 5.528-1.75 4.18A4.894 4.894 0 0 0 2 14.198v-2.93c0-2.84 2.02-5.21 4.71-5.74ZM21.519 11.268v2.93c0-2.2-1.46-4.07-3.46-4.67.52-1.3.67-2.32.42-3.17-.02-.09-.05-.18-.09-.26 1.86.96 3.13 2.93 3.13 5.17Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "proof-of-funds-sm") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M10.752 16.86v2.03c0 1.72-1.6 3.11-3.57 3.11-1.97 0-3.58-1.39-3.58-3.11v-2.03c0 1.72 1.6 2.94 3.58 2.94 1.97 0 3.57-1.23 3.57-2.94Z" />
          <path d="M10.75 14.11c0 .5-.14.96-.38 1.36-.59.97-1.8 1.58-3.2 1.58-1.4 0-2.61-.62-3.2-1.58-.24-.4-.38-.86-.38-1.36 0-.86.4-1.63 1.04-2.19.65-.57 1.54-.91 2.53-.91.99 0 1.88.35 2.53.91.66.55 1.06 1.33 1.06 2.19Z" />
          <path d="M10.752 14.11v2.75c0 1.72-1.6 2.94-3.57 2.94-1.97 0-3.58-1.23-3.58-2.94v-2.75c0-1.72 1.6-3.11 3.58-3.11.99 0 1.88.35 2.53.91.64.56 1.04 1.34 1.04 2.2ZM22 10.97v2.06c0 .55-.44 1-1 1.02h-1.96c-1.08 0-2.07-.79-2.16-1.87-.06-.63.18-1.22.6-1.63.37-.38.88-.6 1.44-.6H21c.56.02 1 .47 1 1.02Z" />
          <path d="M2 10.5v-2c0-2.72 1.64-4.62 4.19-4.94.26-.04.53-.06.81-.06h9c.26 0 .51.01.75.05C19.33 3.85 21 5.76 21 8.5v1.45h-2.08c-.56 0-1.07.22-1.44.6-.42.41-.66 1-.6 1.63.09 1.08 1.08 1.87 2.16 1.87H21v1.45c0 3-2 5-5 5h-2.5" />
        </g>
      </svg>
    );
  }

  if (props.type === "close") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={21}
        fill="none"
        {...props}
      >
        <g stroke="#5D5D5D" strokeLinecap="round" strokeWidth={3}>
          <path d="m2 2 16.97 16.97M18.97 2 2 18.97" />
        </g>
      </svg>
    );
  }

  if (props.type === "close-filled") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
      >
        <path
          fill="#D1D1D1"
          d="M7.999 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8Zm3.085-10.058a.727.727 0 1 0-1.028-1.028L7.999 6.971 5.942 4.914a.727.727 0 0 0-1.028 1.028L6.97 8l-2.057 2.057a.728.728 0 1 0 1.028 1.029L8 9.028l2.057 2.058a.728.728 0 1 0 1.028-1.029L9.027 8l2.057-2.058Z"
        />
      </svg>
    );
  }

  if (props.type === "person") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M12 12a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM20.59 22c0-3.87-3.85-7-8.59-7s-8.59 3.13-8.59 7" />
        </g>
      </svg>
    );
  }

  if (props.type === "logout") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path fill="#151515" d="M17.44 14.62 20 12.06 17.44 9.5" />
        <path
          stroke="#151515"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
          d="M17.44 14.62 20 12.06 17.44 9.5M9.76 12.06h10.17M11.76 20c-4.42 0-8-3-8-8s3.58-8 8-8"
        />
      </svg>
    );
  }

  if (props.type === "settings") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
        >
          <path d="M5 10h2c2 0 3-1 3-3V5c0-2-1-3-3-3H5C3 2 2 3 2 5v2c0 2 1 3 3 3ZM17 10h2c2 0 3-1 3-3V5c0-2-1-3-3-3h-2c-2 0-3 1-3 3v2c0 2 1 3 3 3ZM17 22h2c2 0 3-1 3-3v-2c0-2-1-3-3-3h-2c-2 0-3 1-3 3v2c0 2 1 3 3 3ZM5 22h2c2 0 3-1 3-3v-2c0-2-1-3-3-3H5c-2 0-3 1-3 3v2c0 2 1 3 3 3Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "close-sm") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11}
        height={11}
        fill="none"
        {...props}
      >
        <g stroke="#5D5D5D" strokeLinecap="round" strokeWidth={1.5}>
          <path d="m1 1 8.485 8.485M9.485 1 1 9.485" />
        </g>
      </svg>
    );
  }

  if (props.type === "back") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M15 19.92 8.48 13.4c-.77-.77-.77-2.03 0-2.8L15 4.08"
        />
      </svg>
    );
  }

  if (props.type === "next") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="m8.91 19.92 6.52-6.52c.77-.77.77-2.03 0-2.8L8.91 4.08"
        />
      </svg>
    );
  }

  if (props.type === "yellow-check") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={159}
        height={145}
        fill="none"
        {...props}
      >
        <rect
          width={51.543}
          height={51.543}
          x={14.869}
          y={0.792}
          fill="#FFFD86"
          opacity={0.5}
          rx={10}
        />
        <rect
          width={29.359}
          height={29.359}
          x={128.82}
          y={33.663}
          fill="#FFFD86"
          opacity={0.5}
          rx={10}
        />
        <rect
          width={31.533}
          height={31.533}
          x={0.82}
          y={75.663}
          fill="#FEFFC1"
          opacity={0.5}
          rx={8}
        />
        <rect
          width={36.593}
          height={36.593}
          x={115.107}
          y={107.614}
          fill="#FEFFC1"
          opacity={0.5}
          rx={8}
        />
        <circle cx={84.297} cy={79.168} r={60} fill="gold" />
        <g filter="url(#a)">
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={8}
            d="m61.58 82.413 12.98 12.98 32.452-32.451"
          />
        </g>
        <defs>
          <filter
            id="a"
            width={113.432}
            height={100.451}
            x={27.58}
            y={32.942}
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={15} />
            <feColorMatrix values="0 0 0 0 0.290196 0 0 0 0 0.227451 0 0 0 0 1 0 0 0 0.3 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1178_9077"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_1178_9077"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  }

  if (props.type === "checked-circle") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g id="vuesax/linear/tick-circle">
          <g id="tick-circle">
            <path
              id="Vector"
              d="M8.00016 14.6666C11.6668 14.6666 14.6668 11.6666 14.6668 7.99998C14.6668 4.33331 11.6668 1.33331 8.00016 1.33331C4.3335 1.33331 1.3335 4.33331 1.3335 7.99998C1.3335 11.6666 4.3335 14.6666 8.00016 14.6666Z"
              stroke={props.stroke || "#888888"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M5.1665 8.00001L7.05317 9.88668L10.8332 6.11334"
              stroke={props.stroke || "#888888"}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }

  if (props.type === "drag-drop") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
        {...props}
      >
        <g
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M12 22.667v-8l-2.666 2.666M12 14.667l2.667 2.666" />
          <path d="M29.333 13.333V20c0 6.667-2.666 9.333-9.333 9.333h-8c-6.667 0-9.334-2.666-9.334-9.333v-8c0-6.667 2.667-9.333 9.334-9.333h6.666" />
          <path d="M29.333 13.333H24c-4 0-5.334-1.333-5.334-5.333V2.667l10.667 10.666Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "kyc") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M12 12a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM3.41 22c0-3.87 3.85-7 8.59-7 .96 0 1.89.13 2.76.37" />
          <path
            strokeMiterlimit={10}
            d="M22 18c0 .75-.21 1.46-.58 2.06-.21.36-.48.68-.79.94-.7.63-1.62 1-2.63 1a3.97 3.97 0 0 1-3.42-1.94A3.92 3.92 0 0 1 14 18c0-1.26.58-2.39 1.5-3.12A3.999 3.999 0 0 1 22 18Z"
          />
          <path d="m16.44 18 .99.99 2.13-1.97" />
        </g>
      </svg>
    );
  }

  if (props.type === "security") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path
            strokeMiterlimit={10}
            d="M19.79 14.93a7.575 7.575 0 0 1-7.6 1.87l-4.71 4.7c-.34.35-1.01.56-1.49.49l-2.18-.3c-.72-.1-1.39-.78-1.5-1.5l-.3-2.18c-.07-.48.16-1.15.49-1.49l4.7-4.7c-.8-2.6-.18-5.55 1.88-7.6 2.95-2.95 7.74-2.95 10.7 0 2.96 2.95 2.96 7.76.01 10.71ZM6.89 17.49l2.3 2.3"
          />
          <path d="M14.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "preference") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M11.97 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z" />
          <path d="M7.21 16A5.831 5.831 0 0 0 12 18.5c1.98 0 3.73-.99 4.79-2.5" />
        </g>
      </svg>
    );
  }

  if (props.type === "image") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M6.76 22h10.48c2.76 0 3.86-1.69 3.99-3.75l.52-8.26A3.753 3.753 0 0 0 18 6c-.61 0-1.17-.35-1.45-.89l-.72-1.45C15.37 2.75 14.17 2 13.15 2h-2.29c-1.03 0-2.23.75-2.69 1.66l-.72 1.45C7.17 5.65 6.61 6 6 6 3.83 6 2.11 7.83 2.25 9.99l.52 8.26C2.89 20.31 4 22 6.76 22ZM10.5 8h3" />
          <path d="M12 18c1.79 0 3.25-1.46 3.25-3.25S13.79 11.5 12 11.5s-3.25 1.46-3.25 3.25S10.21 18 12 18Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "clock") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2s10 4.48 10 10Z" />
          <path d="m15.71 15.18-3.1-1.85c-.54-.32-.98-1.09-.98-1.72v-4.1" />
        </g>
      </svg>
    );
  }

  if (props.type === "calendar") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g stroke="#5D5D5D" strokeLinecap="round" strokeLinejoin="round">
          <path
            strokeMiterlimit={10}
            strokeWidth={1.5}
            d="M8 2v3M16 2v3M3.5 9.09h17M21 8.5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
          />
          <path
            strokeWidth={2}
            d="M11.995 13.7h.01M8.294 13.7h.01M8.294 16.7h.01"
          />
        </g>
      </svg>
    );
  }

  if (props.type === "silver-agent") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g stroke="#00C06A" strokeWidth={1.5}>
          <path d="M10.409 2.652a2.25 2.25 0 0 1 3.182 0l7.757 7.757a2.25 2.25 0 0 1 0 3.182l-7.757 7.757a2.25 2.25 0 0 1-3.182 0l-7.757-7.757a2.25 2.25 0 0 1 0-3.182l7.757-7.757Z" />
          <path d="M11.824 6.291a.25.25 0 0 1 .354 0l5.533 5.533a.25.25 0 0 1 0 .354l-5.533 5.533a.25.25 0 0 1-.354 0l-5.533-5.533a.25.25 0 0 1 0-.354l5.533-5.533Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "gold-agent") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g stroke="#B0B0B0" strokeWidth={1.5}>
          <path d="M10.678 1.888a2.25 2.25 0 0 1 2.644 0l7.887 5.73a2.25 2.25 0 0 1 .817 2.515l-3.012 9.27a2.25 2.25 0 0 1-2.14 1.555H7.126a2.25 2.25 0 0 1-2.14-1.554l-3.012-9.271a2.25 2.25 0 0 1 .818-2.516l7.886-5.73Z" />
          <path d="M11.854 6.088a.25.25 0 0 1 .294 0l5.432 3.946a.25.25 0 0 1 .09.28l-2.074 6.385a.25.25 0 0 1-.238.173H8.644a.25.25 0 0 1-.238-.173l-2.075-6.386a.25.25 0 0 1 .091-.28l5.432-3.945Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "diamond-agent") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g stroke="#B0B0B0" strokeWidth={1.5}>
          <path d="M11.139 1.168a2.25 2.25 0 0 1 1.722 0l6.19 2.564c.55.228.989.666 1.217 1.218l2.564 6.189a2.25 2.25 0 0 1 0 1.722l-2.564 6.19a2.25 2.25 0 0 1-1.218 1.217l-6.189 2.564a2.25 2.25 0 0 1-1.722 0l-6.19-2.564a2.25 2.25 0 0 1-1.217-1.218l-2.564-6.189a2.25 2.25 0 0 1 0-1.722l2.564-6.19A2.25 2.25 0 0 1 4.95 3.733l6.189-2.564Z" />
          <path d="M11.905 5.905a.25.25 0 0 1 .192 0l4.147 1.718a.25.25 0 0 1 .135.135l1.718 4.147a.25.25 0 0 1 0 .192l-1.718 4.147a.25.25 0 0 1-.135.135l-4.147 1.718a.25.25 0 0 1-.192 0l-4.147-1.718a.25.25 0 0 1-.135-.135l-1.718-4.147a.25.25 0 0 1 0-.192l1.718-4.147a.25.25 0 0 1 .135-.135l4.147-1.718Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "hamburger") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
        {...props}
      >
        <path d="M0 96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z" />
      </svg>
    );
  }

  if (props.type === "play-video") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={32}
        height={32}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path d="M15.96 29.333c7.364 0 13.334-5.97 13.334-13.334 0-7.363-5.97-13.333-13.334-13.333S2.627 8.636 2.627 15.999c0 7.364 5.97 13.334 13.333 13.334Z" />
          <path
            strokeMiterlimit={10}
            d="M11.654 16.308V14.08c0-2.774 1.96-3.907 4.36-2.52l1.933 1.12 1.934 1.12c2.4 1.386 2.4 3.653 0 5.04l-1.934 1.12-1.933 1.12c-2.4 1.387-4.36.253-4.36-2.52v-2.253Z"
          />
        </g>
      </svg>
    );
  }

  if (props.type === "ngn-flag") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={12}
        fill="none"
        {...props}
      >
        <mask
          id="a"
          width={16}
          height={12}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path fill="#fff" d="M0 0h16v12H0z" />
        </mask>
        <g fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
          <path fill="#F7FCFF" d="M0 0h16v12H0V0Z" />
          <path fill="#093" d="M11 0h5v12h-5V0ZM0 0h5v12H0V0Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "inactive-radio") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <path
          stroke="#B0B0B0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10Z"
        />
      </svg>
    );
  }

  if (props.type === "active-radio") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#151515"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M12.667 22c5.5 0 10-4.5 10-10s-4.5-10-10-10-10 4.5-10 10 4.5 10 10 10Z" />
          <path d="m8.417 12 2.83 2.83 5.67-5.66" />
        </g>
      </svg>
    );
  }

  if (props.type === "approved-stat") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
      >
        <g
          stroke="#00C06A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M9 22.6h6c5 0 7-2 7-7v-6c0-5-2-7-7-7H9c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" />
          <path d="m7.75 12.6 2.83 2.83 5.67-5.66" />
        </g>
      </svg>
    );
  }

  if (props.type === "ongoing-stat") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
      >
        <g
          stroke="#7A30AB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="m16.5 10.299-4.2 4.2-1.6-2.4-3.2 3.2" />
          <path d="M14.5 10.299h2v2" />
          <path d="M9 22.799h6c5 0 7-2 7-7v-6c0-5-2-7-7-7H9c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "doc-required-stat") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#D19E00"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M9 17v-6l-2 2M9 11l2 2" />
          <path d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5" />
          <path d="M22 10h-4c-3 0-4-1-4-4V2l8 8Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "rejected-stat") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
      >
        <g
          stroke="#FC2642"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="m9.17 15.03 5.66-5.66M14.83 15.03 9.17 9.37M9 22.2h6c5 0 7-2 7-7v-6c0-5-2-7-7-7H9c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "disbursed-stat") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
      >
        <g
          stroke="#413FE5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M19.5 12.9a2.5 2.5 0 0 1 2.5-2.5v-1c0-4-1-5-5-5H7c-4 0-5 1-5 5v.5a2.5 2.5 0 0 1 0 5v.5c0 4 1 5 5 5h10c4 0 5-1 5-5a2.5 2.5 0 0 1-2.5-2.5Z" />
          <path strokeDasharray="5 5" d="M10 4.4v16" />
        </g>
      </svg>
    );
  }

  if (props.type === "bin") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="vuesax/linear/trash">
          <g id="trash">
            <path
              id="Vector"
              d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
              stroke={props.stroke || "#5D5D5D"}
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_2"
              d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
              stroke={props.stroke || "#5D5D5D"}
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_3"
              d="M18.8499 9.14001L18.1999 19.21C18.0899 20.78 17.9999 22 15.2099 22H8.7899C5.9999 22 5.9099 20.78 5.7999 19.21L5.1499 9.14001"
              stroke={props.stroke || "#5D5D5D"}
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_4"
              d="M10.3301 16.5H13.6601"
              stroke={props.stroke || "#5D5D5D"}
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_5"
              d="M9.5 12.5H14.5"
              stroke={props.stroke || "#5D5D5D"}
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }

  if (props.type === "half-loading") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 155875">
          <path
            id="Ellipse 11"
            d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM2.4 12C2.4 17.3019 6.69807 21.6 12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69807 17.3019 2.4 12 2.4C6.69807 2.4 2.4 6.69807 2.4 12Z"
            fill="#EDFFF7"
          />
          <path
            id="Ellipse 10"
            d="M22.8 12C23.4627 12 24.0062 12.5386 23.9401 13.198C23.665 15.9406 22.4515 18.519 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.54845 18.519 0.335015 15.9406 0.0599168 13.198C-0.00622779 12.5386 0.537258 12 1.2 12C1.86274 12 2.39226 12.5393 2.47487 13.1969C2.73931 15.3017 3.69588 17.2723 5.21178 18.7882C7.01213 20.5886 9.45392 21.6 12 21.6C14.5461 21.6 16.9879 20.5886 18.7882 18.7882C20.3041 17.2723 21.2607 15.3017 21.5251 13.1969C21.6077 12.5393 22.1373 12 22.8 12Z"
            fill="#00E785"
          />
        </g>
      </svg>
    );
  }

  if (props.type === "plus") {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="vuesax/linear/add-square">
          <g id="add-square">
            <path
              id="Vector"
              d="M8.5 12H16.5"
              stroke="#5D5D5D"
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_2"
              d="M12.5 16V8"
              stroke="#5D5D5D"
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Vector_3"
              d="M9.5 22H15.5C20.5 22 22.5 20 22.5 15V9C22.5 4 20.5 2 15.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22Z"
              stroke="#5D5D5D"
              strokeWidth="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  }

  if (props.type === "download-doc") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#00C06A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M9 11v6l2-2M9 17l-2-2" />
          <path d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5" />
          <path d="M22 10h-4c-3 0-4-1-4-4V2l8 8Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "location") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g stroke="#5D5D5D" strokeWidth={1.5}>
          <path d="M12 13.43a3.12 3.12 0 1 0 0-6.24 3.12 3.12 0 0 0 0 6.24Z" />
          <path d="M3.62 8.49c1.97-8.66 14.8-8.65 16.76.01 1.15 5.08-2.01 9.38-4.78 12.04a5.193 5.193 0 0 1-7.21 0c-2.76-2.66-5.92-6.97-4.77-12.05Z" />
        </g>
      </svg>
    );
  }

  if (props.type === "school") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
      >
        <g
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="m10.05 2.531-6.02 3.93c-1.93 1.26-1.93 4.08 0 5.34l6.02 3.93c1.08.71 2.86.71 3.94 0l5.99-3.93c1.92-1.26 1.92-4.07 0-5.33l-5.99-3.93c-1.08-.72-2.86-.72-3.94-.01Z" />
          <path d="m5.63 13.078-.01 4.69c0 1.27.98 2.63 2.18 3.03l3.19 1.06c.55.18 1.46.18 2.02 0l3.19-1.06c1.2-.4 2.18-1.76 2.18-3.03v-4.64M21.4 15V9" />
        </g>
      </svg>
    );
  }

  if (props.type === "checked-bullet") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        fill="none"
        {...props}
      >
        <path
          stroke="#151515"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3.2}
          d="m18 24 4 4 8-8m12 4c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18Z"
        />
      </svg>
    );
  }

  if (props.type === "bank") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.5}
        >
          <path
            stroke="#5D5D5D"
            d="m12.37 2.15 9 3.6c.35.14.63.56.63.93V10c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V6.68c0-.37.28-.79.63-.93l9-3.6c.2-.08.54-.08.74 0ZM22 22H2v-3c0-.55.45-1 1-1h18c.55 0 1 .45 1 1v3Z"
          />
          <path
            stroke="#212121"
            d="M4 18v-7M8 18v-7M12 18v-7M16 18v-7M20 18v-7M1 22h22M12 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
          />
        </g>
      </svg>
    );
  }

  if (props.type === "url") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        {...props}
      >
        <g
          stroke="#5D5D5D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        >
          <path d="M3.27 12A5.46 5.46 0 0 1 2 8.5C2 5.48 4.47 3 7.5 3h5C15.52 3 18 5.48 18 8.5S15.53 14 12.5 14H10" />
          <path d="M20.73 12A5.46 5.46 0 0 1 22 15.5c0 3.02-2.47 5.5-5.5 5.5h-5C8.48 21 6 18.52 6 15.5S8.47 10 11.5 10H14" />
        </g>
      </svg>
    );
  }

  return null;
};
