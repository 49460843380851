import { AntdSelect, Button, Icon, Input, Text } from "../../atoms";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AuthTemplate } from "../../templates";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AuthContext from "../../../store/contexts/AuthProvider";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { brandTheme } from "../../../theme";
import { toast } from "react-toastify";
import {
  formatSelectOptionForAntd,
  separatePhoneNumberString,
} from "../../../utils";

const FormInputs = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
const FormRow = styled.div`
  display: flex;
  gap: 16px;
`;

export const OnboardPartner = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    companyName: "",
    companyPhoneNumber: "",
    companyWebsite: "",
    phoneNumber: "",
    address: {
      country: "",
      state: "",
      city: "",
      zip: "",
    },
  });
  // eslint-disable-next-line no-unused-vars
  const [stepCount, setStepCount] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth.kycStatus) {
      navigate("/", { replace: true });
    }
  }, [auth.kycStatus, navigate]);

  async function handleSubmit() {
    try {
      const response = await axiosPrivate.patch("/partner/kyc", {
        ...data,
        companyPhoneNumber: separatePhoneNumberString(data?.companyPhoneNumber),
        phoneNumber: separatePhoneNumberString(data?.phoneNumber),
      });

      // Set the auth data here e.g., setAuth({ user, pwd, roles, accessToken });
      if (response.data) {
        setAuth({ ...auth, ...response.data });
        navigate("/");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  return (
    <AuthTemplate
      title="Let's get to know you"
      subtitle="Our KYC process is a quick way for us to know how best to serve you!"
    >
      <FormInputs>
        <Text type="h4">Personal details</Text>
        <FormRow>
          <div className="w-full">
            <Input
              icon={<Icon type="person" />}
              type="text"
              size="md"
              placeholder="First Name"
              autoComplete="off"
              value={data.firstName}
              onChange={(e) =>
                setData((prev) => ({ ...prev, firstName: e.target.value }))
              }
            />
          </div>
          <div className="w-full">
            <Input
              icon={<Icon type="person" />}
              type="text"
              size="md"
              placeholder="Last Name"
              autoComplete="off"
              value={data.lastName}
              onChange={(e) =>
                setData((prev) => ({ ...prev, lastName: e.target.value }))
              }
            />
          </div>
        </FormRow>

        <FormRow>
          <div className="w-full">
            <AntdSelect
              showSearch
              options={formatSelectOptionForAntd(auth.enumValues.genderEnum)}
              placeholder="Gender"
              value={data.gender}
              defaultValue={data.gender}
              setValue={(val) => {
                setData((prev) => ({
                  ...prev,
                  gender: val,
                }));
              }}
              size="md"
            />
          </div>
          <div className="w-full">
            <Input
              size="md"
              type="phone"
              value={data.phoneNumber}
              onChange={(val) =>
                setData((prev) => ({ ...prev, phoneNumber: val }))
              }
            />
          </div>
        </FormRow>

        <Text type="h4">Organization details</Text>
        <FormRow>
          <div className="w-full">
            <Input
              icon={<Icon type="bank" />}
              type="text"
              size="md"
              placeholder="Name"
              autoComplete="off"
              value={data.companyName}
              onChange={(e) =>
                setData((prev) => ({ ...prev, companyName: e.target.value }))
              }
            />
          </div>
        </FormRow>

        <FormRow>
          <div className="w-full">
            <Input
              icon={<Icon type="url" />}
              type="url"
              size="md"
              placeholder="Website URL"
              autoComplete="off"
              value={data.companyWebsite}
              onChange={(e) =>
                setData((prev) => ({ ...prev, companyWebsite: e.target.value }))
              }
            />
          </div>
          <div className="w-full">
            <Input
              size="md"
              type="phone"
              value={data.companyPhoneNumber}
              onChange={(val) =>
                setData((prev) => ({ ...prev, companyPhoneNumber: val }))
              }
            />
          </div>
        </FormRow>

        <FormRow>
          <div className="w-full">
            <Input
              type="text"
              size="md"
              padding="12px"
              placeholder="City"
              autoComplete="off"
              value={data.address.city}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  address: { ...prev.address, city: e.target.value },
                }))
              }
            />
          </div>

          <div className="w-full">
            <Input
              type="text"
              size="md"
              padding="12px"
              placeholder="Postal / zip code"
              autoComplete="off"
              value={data.address.zip}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  address: { ...prev.address, zip: e.target.value },
                }))
              }
            />
          </div>
        </FormRow>

        <FormRow>
          <div className="w-full">
            <Input
              type="text"
              size="md"
              placeholder="State"
              padding="12px"
              autoComplete="off"
              value={data.address.state}
              onChange={(e) =>
                setData((prev) => ({
                  ...prev,
                  address: { ...prev.address, state: e.target.value },
                }))
              }
            />
          </div>
          <div className="w-full">
            <AntdSelect
              showSearch
              options={formatSelectOptionForAntd(auth?.enumValues?.countryEnum)}
              placeholder="Select country"
              value={data.address?.country}
              defaultValue={data.address?.country}
              setValue={(val) => {
                setData((prev) => ({
                  ...prev,
                  address: { ...prev.address, country: val },
                }));
              }}
              size="md"
            />
          </div>
        </FormRow>

        <Button
          onClick={handleSubmit}
          width="100%"
          size="md"
          bgcolor={brandTheme.brand.primary}
        >
          Submit
        </Button>
      </FormInputs>
    </AuthTemplate>
  );
};
