import { useRef, useState } from "react";
import styled from "styled-components";
import { useDetectOutsideClick } from "../../../hooks/useDetectOutsideClick";

const Dropdown = styled.div`
  background-color: white;
  width: ${({ width }) => width || "fit-content"};
  min-width: ${({ $minWidth }) => $minWidth};
  min-height: ${({ minHeight }) => minHeight};
  border-radius: 16px;
  padding: 24px;
  font-size: 0.8rem;
  position: absolute;
  top: ${({ top }) => top || "64px"};
  right: ${({ right }) => right};
  left: ${({ left }) => left};
  z-index: 9999999;
  height: fit-content;
  overflow-y: auto;

  -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
`;

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin: ${({ margin }) => margin};
`;

export const DropdownMenu = ({
  margin,
  minHeight,
  minWidth,
  text,
  children,
  right,
  left,
}) => {
  const [menuActive, setMenuActive] = useState(false);
  const ref = useRef(null);

  useDetectOutsideClick(ref, () => setMenuActive(false));

  return (
    <Wrapper
      ref={ref}
      margin={margin}
      onClick={() => setMenuActive(!menuActive)}
    >
      <>{text}</>
      {menuActive && (
        <Dropdown
          right={right}
          left={left}
          minHeight={minHeight}
          $minWidth={minWidth}
        >
          {children}
        </Dropdown>
      )}
    </Wrapper>
  );
};
