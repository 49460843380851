import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { pageAnimationConfig } from "../../../utils";
import { Button, SelectInput, Text } from "../../atoms";
import { africredColors } from "../../../theme/colors";
import { brandTheme, screen } from "../../../theme";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";

const Wrapper = styled(motion.div)``;
const WhiteArea = styled.div`
  display: flex;
  padding: 48px 12px;
  gap: px;
  border-radius: 32px;
  background: ${africredColors.neutrals.white[10]};
  margin-top: 28px;
  height: fit-content;
  justify-content: center;
  align-items: center;

  @media only screen and (${screen.md}) {
    padding: 48px;
  }
`;

export const PreferenceSettings = () => {
  const axiosPrivate = useAxiosPrivate();
  const auth = useAuth();
  const [preference, setPreference] = useState(auth?.personalPreference || {});
  const handleSubmit = async () => {
    try {
      const response = await axiosPrivate.patch(
        "/partner/personal-preference-update",
        preference
      );
      if (response.data) {
        toast.success("Preference updated successfully");
      }
    } catch (err) {
      toast.error(
        err.response.data.message ||
          "An error occurred, please try again later."
      );
    }
  };

  return (
    <Wrapper {...pageAnimationConfig}>
      <Text type="h3" weight="medium">
        Personal preference
      </Text>
      <WhiteArea>
        <div className="flex flex-col gap-4 w-full sm:w-1/2 2xl:w-1/3">
          <Text type="h5" weight="medium">
            Default Currency
          </Text>
          <Text type="p">
            This will affect how price and amount will be displayed
          </Text>
          <SelectInput
            options={auth.enumValues?.currency || []}
            value={preference?.currency}
            setValue={(item) =>
              setPreference((prev) => ({ ...prev, currency: item }))
            }
            icon="ngn-flag"
          />

          <Text type="h5" weight="medium">
            My Time Zone
          </Text>
          <Text type="p">
            When dates are displayed, show them in this timezone.
          </Text>
          <SelectInput
            options={auth.enumValues?.timeZone || []}
            value={preference?.timeZone}
            setValue={(item) =>
              setPreference((prev) => ({ ...prev, timeZone: item }))
            }
            icon="clock"
          />

          <Text type="h5" weight="medium">
            My Date Format
          </Text>
          <Text type="p">
            When dates are displayed, show them in this format.
          </Text>

          <SelectInput
            options={auth.enumValues?.dateFormat || []}
            value={preference?.dateFormat}
            setValue={(item) =>
              setPreference((prev) => ({ ...prev, dateFormat: item }))
            }
            icon="calendar"
          />

          <Text type="h5" weight="medium">
            My Time Format
          </Text>
          <Text type="p">
            When times are displayed, show them in this format.
          </Text>

          <SelectInput
            options={auth.enumValues?.timeFormat || []}
            value={preference?.timeFormat}
            setValue={(item) =>
              setPreference((prev) => ({ ...prev, timeFormat: item }))
            }
            icon="clock"
          />

          <Button
            onClick={handleSubmit}
            width="100%"
            size="md"
            bgcolor={brandTheme.brand.primary}
          >
            Update preference
          </Button>
        </div>
      </WhiteArea>
    </Wrapper>
  );
};
